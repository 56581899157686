import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, IconButton } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { useHttpApi } from 'state';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

// Modal component to show Plaid account information
const AccountInformationModal = ({ investorId }) => {
  const [accountInfo, setAccountInfo] = useState(null);
  const [accountInfoNotAvailable, setAccountInfoNotAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState();
  const { getAccountDetailInformation } = useHttpApi();

  const handleClose = () => {
    setAccountInfo(null);
    setAccountInfoNotAvailable(false);
    setModalOpen(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    if (modalOpen) {
      fetchAccountInfo();
    }
  }, [modalOpen]);

  const fetchAccountInfo = async () => {
    setLoading(true);
    try {
      // Replace this with actual API call logic to Plaid
      const response = await getAccountDetailInformation(investorId);
      if (response.status === 'success') {
        setAccountInfo(response.data);
      } else {
        setAccountInfoNotAvailable(true);
      }
    } catch (error) {
      console.error('Error fetching account info:', error);
      setAccountInfoNotAvailable(true);
    } finally {
      setLoading(false);
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4
  };

  return (
    <>
      <AccountBalanceIcon
        sx={{
          fontSize: '2rem',
          p: 1,
          m: 0,

          backgroundColor: '#F5F8FA',

          mt: '0.2rem',
          mx: 1,
          cursor: 'pointer'
        }}
        onClick={handleOpen}
      />

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle} mt={2}>
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
              <Typography>Loading...</Typography>
            </Box>
          )}
          {!loading && accountInfoNotAvailable && (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
              <Typography>Account Information Is Not Available</Typography>
            </Box>
          )}

          {!loading && accountInfo && (
            <>
              <Typography>
                <strong>ACH Account Name:</strong> {accountInfo.accounts[0].name}
              </Typography>
              <Typography>
                <strong>ACH Account Number:</strong> {accountInfo.numbers.ach[0].account}
              </Typography>
              <Typography>
                <strong>ACH Account ID:</strong> {accountInfo.numbers.ach[0].account_id}
              </Typography>
              <Typography>
                <strong>ACH Routint Number:</strong> {accountInfo.numbers.ach[0].routing}
              </Typography>
              <Typography>
                <strong>ACH Wire Routing Number:</strong> {accountInfo.numbers.ach[0].wire_routing}
              </Typography>
            </>
          )}

          {!loading && !accountInfo && !accountInfoNotAvailable && (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={fetchAccountInfo}>
                Fetch Account Info
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default AccountInformationModal;
