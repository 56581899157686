import {
  Container,
  Card,
  Grid,
  Box,
  CardContent,
  Typography,
  TextField,
  Divider,
  Button,
  IconButton,
  InputLabel,
  FormLabel,
  ListItem,
  Select,
  FormControl,
  FormControlLabel,
  Switch
} from '@mui/material';
import Page from 'components/Page';
import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import PropTypes from 'prop-types';
import { useAuthStateShared, useBusinessEntityState, useWeb3, useHttpApi } from 'state';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { ethToXdcAddress, stringToBytes32 } from 'helpers/web3';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LoadingActionButton from 'helpers/LoadingActionButton';
import { format, isBefore } from 'date-fns';

const ViewToken = () => {
  const { currentToken: token, currentTokenRuleDetails: rules } = useBusinessEntityState();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { web3, getTokenDocument } = useWeb3();
  const { get0xSha256Hash } = useHttpApi();
  const [fetchedHash, setFetchedHash] = useState('');
  const [contractDocumentHash, setContractDocumentHash] = useState('Fetching...');
  const [enterHashManually, setEnterHashManually] = useState(false);
  const today = new Date();
  // get today as per issue date format
  const todayDate = format(today, 'yyyy-MM-dd');

  console.log('Check', token);
  const TextFieldInput = ({ header, subheader, readOnlyValue = true }) => {
    return (
      <TextField
        id="read-only-input"
        label={header}
        defaultValue={subheader}
        fullWidth
        fontFamily="Poppins"
        sx={{ fontWeight: '900' }}
        InputProps={{
          readOnly: readOnlyValue
        }}
        variant="standard"
      />
    );
  };
  TextFieldInput.propTypes = {
    header: PropTypes.string,
    subheader: PropTypes.string
  };

  // useEffect(() => {
  //   const fetchHashFromContract = async () => {
  //     try {
  //       const res = await getTokenDocument(
  //         token.deployment_address,
  //         stringToBytes32(token.document_name)
  //       );
  //       setContractDocumentHash(res['1']);
  //     } catch (e) {
  //       enqueueSnackbar(e.message, { variant: 'error' });
  //     }
  //   };
  //   fetchHashFromContract();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const get0xedHash = (hString) => {
    if (!hString.startsWith('0x')) {
      return `0x${hString}`;
    }
    return hString;
  };

  const getVerificationButtonVariant = () => {
    if (fetchedHash === '') {
      return 'outlined';
    }
    if (get0xedHash(fetchedHash) === contractDocumentHash) {
      return 'gradient';
    }
    return 'contained';
  };

  const getVerificationButtonColor = () => {
    if (fetchedHash === '') {
      return 'primary';
    }
    if (get0xedHash(fetchedHash) === contractDocumentHash) {
      return 'success';
    }
    return 'error';
  };

  const getVerificationButtonText = () => {
    if (fetchedHash === '') {
      return enterHashManually ? 'Enter Hash' : 'Verify Document';
    }
    if (get0xedHash(fetchedHash) === contractDocumentHash) {
      return `${enterHashManually ? 'Hashes' : 'Documents'} Match`;
    }
    return `${enterHashManually ? 'Hashes' : 'Documents'} Do Not Match`;
  };

  const getVerificationButtonDisableRipple = () => {
    if (fetchedHash === '') {
      return false;
    }
    return true;
  };

  return (
    <>
      <Page title="View Token">
        <Container sx={{ my: 2 }}>
          <Box sx={{ my: 2 }}>
            <Breadcrumbs pageHead="Token Term Sheet" />
          </Box>
          <Card>
            <Container>
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold',
                  mt: 4,
                  mb: 1,
                  ml: 4
                }}
              >
                View Token Details
              </Typography>
              <Divider
                sx={{
                  width: '100%',
                  backgroundColor: '#f5f8fa',
                  border: '2px solid #f5f8fa'
                }}
              />
              <CardContent sx={{ mb: 2 }}>
                <Card sx={{ mt: 2, backgroundColor: '#f5f8fa' }}>
                  <CardContent>
                    <Typography sx={{ pl: 6, fontWeight: '600' }}>Token Header</Typography>
                    <Grid container spacing={2} sx={{ width: '100%', mt: 1, pr: 6, pl: 6 }}>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Issuer name"
                          subheader={token.issuer_id.issuer_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Issue Currency"
                          subheader="USD"
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token Name" subheader={token.token_name} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token Symbol" subheader={token.token_symbol} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token Max Supply" subheader={token.total_supply} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Token Value(USD)" subheader={token.token_value} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput header="Max Issue Size(USD)" subheader={token.max_issue_size} />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Token Category"
                          subheader={token.issue_category_id.issue_category_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Issue Type"
                          subheader={token.issue_type_id.issue_type_name}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Fund Manager"
                          subheader={token.fund_manager}
                        />
                      </Grid>
                    </Grid>
                    <Divider sx={{ mt: 4 }} />
                    <Divider sx={{ mb: 4 }} />
                    <Typography sx={{ pl: '50px', fontWeight: '600' }}>
                      Token Economics Terms
                    </Typography>
                    <Grid container spacing={2} sx={{ width: '100%', mt: 1, pr: 6, pl: 6 }}>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Issue Start Date"
                          subheader={token.token_start_date}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Min. Subscription Qty"
                          subheader={token.minimum_subscription}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Blockchain"
                          subheader={token.blockchain}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Token NAV(USD)"
                          subheader={token.token_nav}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Platform Fees(%)"
                          subheader={token.platform_fees}
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Mode of Payment"
                          subheader={token.mode_of_payment}
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Setllement Cycle (T + Days)"
                          subheader={token.settlement_cycle}
                        />
                      </Grid>
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }}>
                        <TextFieldInput
                          header="Use Of Token sale Proceeds"
                          subheader={token.use_of_proceeds}
                        />
                      </Grid>

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />

                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />
                      <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} />

                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 1, mt: 1 }}>
                        <Button
                          variant="outlined"
                          sx={{ width: '100%', display: 'grid' }}
                          onClick={() => {
                            window.open(token.contract_doc_hash, '_blank');
                          }}
                        >
                          <Grid
                            item
                            lg={12}
                            md={6}
                            xs={12}
                            sx={{ pr: 1, display: 'flex', placeSelf: 'center center' }}
                          >
                            <Typography>Token Terms Document</Typography>
                            <OpenInNewIcon sx={{ ml: 1 }} />
                          </Grid>
                        </Button>
                      </Grid>

                      {/* <Grid item lg={6} md={6} xs={12} sx={{ pr: 1, display: 'grid' }}>
                        <TextField
                          fullWidth
                          label="Document Hash"
                          value={contractDocumentHash}
                          variant="standard"
                        />
                      </Grid> */}

                      {/* <Grid item lg={4} md={6} xs={12} sx={{ pr: 1, mt: 2, display: 'grid' }}>
                        <Typography
                          sx={{
                            placeSelf: 'center end',
                            fontWeight: 'bold'
                          }}
                        >
                          Verify Document Hash
                        </Typography>
                      </Grid> */}

                      {/* <Grid item lg={4} md={6} xs={12} sx={{ pr: 1, mt: 2 }}>
                        {enterHashManually ? (
                          <TextField
                            fullWidth
                            placeholder="SHA256 Hash"
                            size="small"
                            value={fetchedHash}
                            onChange={(e) => setFetchedHash(e.target.value)}
                          />
                        ) : (
                          <TextField
                            fullWidth
                            id="document-to-verify"
                            placeholder="Upload Document"
                            type="file"
                            size="small"
                            inputProps={{ accept: '.pdf' }}
                            onChange={(e) => {
                              setFetchedHash('');
                            }}
                          />
                        )}
                      </Grid> */}

                      {/* <Grid item lg={4} md={6} xs={12} sx={{ pr: 1, mt: 2, display: 'grid' }}>
                        {fetchedHash === '' && enterHashManually ? (
                          <></>
                        ) : (
                          <Button
                            variant={getVerificationButtonVariant()}
                            color={getVerificationButtonColor()}
                            sx={{ placeSelf: 'center start' }}
                            disableRipple={fetchedHash !== '' || enterHashManually}
                            onClick={async () => {
                              if (!enterHashManually) {
                                if (fetchedHash === '') {
                                  const fileToUpload =
                                    document.getElementById('document-to-verify').files[0];
                                  if (fileToUpload) {
                                    const res = await get0xSha256Hash({
                                      file: fileToUpload
                                    });
                                    setFetchedHash(res.sha256_hash);
                                  }
                                }
                              }
                            }}
                          >
                            <Typography>{getVerificationButtonText()}</Typography>
                          </Button>
                        )}
                      </Grid> */}

                      {/* <Grid item lg={4} md={6} xs={12} sx={{ pr: 1 }} /> */}

                      {/* <Grid item lg={4} md={6} xs={12} sx={{ display: 'grid' }}>
                        <FormControlLabel
                          sx={{ placeSelf: 'center center' }}
                          control={
                            <Switch
                              checked={enterHashManually}
                              onChange={(e) => {
                                setEnterHashManually(e.target.checked);
                                setFetchedHash('');
                              }}
                            />
                          }
                          label="Enter Hash Manually"
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Grid> */}
                    </Grid>

                    {/* <Divider sx={{ mt: 4 }} /> */}
                    {/* <Divider sx={{ mb: 4 }} />
                    <Typography sx={{ pl: 6, fontWeight: '600' }}>Other Highlights</Typography>
                    <Grid container spacing={2} sx={{ width: '100%', mt: 1, pr: 6, pl: 6 }}>
                      {token.originator_id && (
                        <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                          <TextFieldInput
                            header="Originator"
                            subheader={token.originator_id.originator_name}
                          />
                        </Grid>
                      )}
                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                        <TextFieldInput
                          header="KYC Provider"
                          subheader={token.kyc_provider_id.kyc_provider_name}
                        />
                      </Grid>

                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                        <Typography sx={{ fontSize: '0.7rem', color: '#888888' }}>
                          Use of proceeds
                        </Typography>
                        <Typography>{token.use_of_proceeds}</Typography>
                      </Grid>
                      <Grid item lg={6} md={6} xs={12} sx={{ pr: 3 }}>
                        <Typography sx={{ fontSize: '0.7rem', color: '#888888' }}>
                          Reference Assets
                        </Typography>
                        <Typography>{token.reference_assets}</Typography>
                      </Grid>
                    </Grid> */}
                  </CardContent>
                </Card>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}
                >
                  <Button
                    sx={{
                      px: 3,
                      mr: 1,
                      width: '7.75rem',
                      height: '2.5rem'
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      navigate('/subscription');
                    }}
                  >
                    Back
                  </Button>
                  {/* This below "Add to Wallet button has been commented out 
                  because we are using invisible wallet." */}
                  {/* <LoadingActionButton
                    loadingPosition="start"
                    variant="contained"
                    color="primary"
                    sx={{ mr: 1 }}
                    onClick={() => {
                      const provider = web3.currentProvider;
                      provider.sendAsync(
                        {
                          method: 'metamask_watchAsset',
                          params: {
                            type: 'ERC20',
                            options: {
                              address: token.deployment_address,
                              symbol: token.token_symbol,
                              decimals: 0
                            }
                          },
                          id: Math.round(Math.random() * 100000)
                        },
                        (err, added) => {
                          console.log('provider returned', err, added);
                          if (err || 'error' in added) {
                            enqueueSnackbar('Something went wrong!', { variant: 'error' });
                          } else if (added.result) {
                            enqueueSnackbar('Token added to wallet!', { variant: 'success' });
                          }
                        }
                      );
                    }}
                  >
                    Add to wallet
                  </LoadingActionButton> */}
                  {/* <LoadingActionButton
                    loadingPosition="start"
                    sx={{
                      width: '7.5rem',
                      height: '2.5rem'
                    }}
                    variant="gradient"
                    onClick={() => {
                      if (isBefore(new Date(token.final_maturity_date), new Date(todayDate))) {
                        enqueueSnackbar('Cannot Subscribe After Issue Closer Date', {
                          variant: 'warning'
                        });
                      } else {
                        navigate(`/subscription/form/${token.deployment_address}`);
                      }
                    }}
                  >
                    Subscribe
                  </LoadingActionButton> */}
                </Grid>
              </CardContent>
            </Container>
          </Card>
        </Container>
      </Page>
    </>
  );
};

export default ViewToken;
