import {
  Chip,
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Alert,
  AlertTitle
} from '@mui/material';
import { getChipColor } from 'helpers/handler';
import React from 'react';
import { format, isBefore } from 'date-fns';
import NAIcon from 'components/NAIcon';

const TokenTable = ({ currToken: token }) => {
  const today = new Date();
  // get today as per issue date format
  const todayDate = format(today, 'yyyy-MM-dd');

  return (
    <>
      <Box>
        {/* Token Table */}
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Issue Type</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Issue Size</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Supply</TableCell>
                  {/* <TableCell>Next Payment Date</TableCell> */}
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{token?.token_name}</TableCell>
                  <TableCell>{token?.token_symbol}</TableCell>
                  <TableCell>{token?.issue_type_id?.issue_type_name}</TableCell>
                  <TableCell>{token?.token_currency?.currency_code}</TableCell>
                  <TableCell>{token?.max_issue_size}</TableCell>
                  <TableCell>{token?.token_nav}</TableCell>
                  <TableCell>{token?.total_supply}</TableCell>
                  {/* <TableCell>
                    {token.next_payment_due_date ? token.next_payment_due_date : <NAIcon />}
                  </TableCell> */}
                  {/* <TableCell>
                    <Chip
                      label={token.token_status_id.token_status_desc}
                      color={getChipColor(token.token_status_id.token_status_desc)}
                    />
                  </TableCell> */}
                  <TableCell>{token.token_status_id.token_status_desc}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        {/* {token.token_status_id.token_status === 'BURNT' && (
          <Alert sx={{ mt: 2 }} severity="error">
            <AlertTitle>Alert</AlertTitle>
            Cannot perform any functions on — <strong>BURNT Token</strong>
            {`  ${token.token_name}`}
          </Alert>
        )} */}
        {/* {isBefore(new Date(token.final_maturity_date), new Date(todayDate)) && (
          <Alert sx={{ mt: 2 }} severity="error">
            <AlertTitle>Alert</AlertTitle>
            {`${token.token_name}`}, cannot be Allotted after the Maturity Date is closed
          </Alert>
        )} */}
      </Box>
    </>
  );
};

export default TokenTable;
