import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useIsOwner,
  useKycProvider,
  useInsurer,
  useIsLiquidityPoolOwner,
  useIsIssuer,
  useIsInvestor,
  useIsFundManager
} from 'helpers/rbac';
import IssuerHome from './issuer/IssuerHome';
import OwnerDashboard from './owner/OwnerDashboard';
import KYCHome from './kycprovider/KYCDashboard';
import AllMappedIssuers from './masterData/creditInsurer/AllMappedIssuers';
import LiquidityPoolDashboard from './masterData/liquidityPool/LiquidityPoolDashboard';
import InvestorDashboard from './subscriptions/InvestorDashboard';
import SubscriptionHome from './subscriptions/SubscriptionHome';
import FundManagerHome from './fundManager/FundManagerHome';

const Home = () => {
  const isOwner = useIsOwner();
  const isKycProvider = useKycProvider();
  const isInsurer = useInsurer();
  const isLiquidityPoolOwner = useIsLiquidityPoolOwner();
  const isIssuer = useIsIssuer();
  const isInvestor = useIsInvestor();
  const isFundManager = useIsFundManager();

  const navigate = useNavigate();
  if (isInvestor) {
    navigate('/subscription');
    return <SubscriptionHome />;
  }
  if (isFundManager) {
    navigate('/fund-manager');
    return <FundManagerHome />;
  }
  if (isOwner) {
    return <IssuerHome />;
  }
  if (isInsurer) {
    return <AllMappedIssuers />;
  }
  if (isKycProvider) {
    return <KYCHome />;
  }
  if (isLiquidityPoolOwner) {
    return <LiquidityPoolDashboard />;
  }
  if (isIssuer) {
    return <IssuerHome />;
  } else {
    return <IssuerHome />;
  }
};

export default Home;
