import { useBusinessEntityState, useHttpApi } from 'state';
import TokenTable from './TokenTable';
import {
  Container,
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Card,
  CardContent,
  IconButton
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import CreateIcon from '@mui/icons-material/Create';
import Breadcrumbs from 'components/Breadcrumbs';
import { useEffect, useState } from 'react';
import CopyAddress from 'components/CopyAddress';
import { ethToXdcAddress } from 'helpers/web3';
import EnhancedTable from 'components/tables/EnhancedTable';
import AccountInformationModal from './AccountInformationModal';
import IdentityInformationModal from './IdentityInformationModal';
import { Link } from 'react-router-dom';
import AddressFieldTools from 'components/AddressFieldTools';
import MailComponent from './MailComponent';

const CustomerDetails = () => {
  const { getAllInvestorsByToken } = useHttpApi();
  const [data, setData] = useState([]);
  const { currentToken } = useBusinessEntityState();
  const [skipPageReset, setSkipPageReset] = useState(false);

  const fetchInvestorsByToken = async () => {
    const res = await getAllInvestorsByToken(currentToken?.token_id);
    setData(res);
  };
  useEffect(() => {
    fetchInvestorsByToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    currentToken: token,
    redeemAllotmentsList,
    fetchTokenSummaryData
  } = useBusinessEntityState();

  const headCells = [
    {
      accessor: 'investor_name',
      Header: 'Investor Name',
      show: true
    },
    {
      accessor: 'xinfin_account',
      Header: 'Blockchain Address',
      show: true,
      width: 200,
      Cell: ({ row: { original } }) => {
        return (
          <AddressFieldTools
            address={original.xinfin_account}
            decimals={0}
            showCopyButton
            showInBlockExplorer
          />
        );
      }
    },
    {
      accessor: 'investor_phone_no',
      Header: 'Phone',
      show: true,
      width: 200
    },
    {
      accessor: 'investor_type',
      Header: 'Type',
      show: true,
      width: 200
    },
    // {
    //   accessor: 'xinfin_account',
    //   Header: 'Xinfin Address',
    //   show: true,
    //   width: 200,
    //   Cell: ({ value }) => <CopyAddress addr={ethToXdcAddress(value)} />
    // },
    {
      accessor: 'investor_country_code.country_name',
      Header: 'Country',
      show: true,
      width: 100
    },
    {
      accessor: 'actions',
      Header: 'Actions',
      Cell: ({ row: { original } }) => {
        return <ActionButtons row={original} />;
      },
      show: true
    }
  ];

  const ActionButtons = ({ row }) => {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <IdentityInformationModal investorId={row.investor_id} />
        <AccountInformationModal investorId={row.investor_id} />
        <Box sx={{ mt: 1 }}>
          <MailComponent email={row.investor_email_id} subject="" body="" />
        </Box>
      </Box>
    );
  };

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" pageHead="" />
      <TokenTable currToken={token} />
      <Box sx={{ mt: 4 }}>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="body2"
            color="initial"
            sx={{
              mt: 4,
              mb: 2,
              fontSize: '1.125rem',
              fontWeight: 'bold'
            }}
          >
            Customer KYC & Bank Details
          </Typography>
        </Box>
        <Card>
          <CardContent>
            <EnhancedTable
              tableTitle="Investors"
              columns={headCells}
              refreshFunction={fetchInvestorsByToken}
              data={data}
              setData={setData}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
            />
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default CustomerDetails;
