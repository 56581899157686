import Page from 'components/Page';
import React from 'react';
import {
  Card,
  Container,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import Holding from 'components/masterData/Investor/Holding';
import OpenTransaction from 'components/masterData/Investor/OpenTransaction';
import InvestorIssuanceEscrowTable from 'components/subscritiption/InvestorIssuanceEscrowTable';
import InvestorRedemptionEscrowTable from 'components/subscritiption/InvestorRedemptionEscrowTable';
import InvestorViewAllotment from 'components/subscritiption/InvestorViewAllotment';
import InvestorViewRedemption from 'components/subscritiption/InvestorViewRedemptions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PaymentTxnTable from 'components/subscritiption/PaymentTxnTable';

const InvestorDashboard = () => {
  return (
    <>
      <Page title="My Investments">
        <Container>
          <Breadcrumbs pageHead="My Investments" />
          {/* TODO: Need to see the implementation details for the same currently Repetivite UI */}
          {/* <Typography
            sx={{
              mt: 2,
              fontSize: '1.125rem',
              fontWeight: 'bold'
            }}
          >
            Holding
          </Typography>
          <Card sx={{ mt: 2 }}>
            <CardContent>
              <Holding />
            </CardContent>
          </Card> */}
          <Card sx={{ mt: 2 }}>
            <OpenTransaction />
          </Card>

          {/* Holding/Allotments */}
          <Accordion
            sx={{
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              borderRadius: '6px',
              px: 4,
              py: 1,
              mt: 4
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold'
                }}
              >
                Holdings
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvestorViewAllotment />
            </AccordionDetails>
          </Accordion>

          {/* <Accordion
            sx={{
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              borderRadius: '6px',
              px: 4,
              py: 1,
              mt: 4
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold'
                }}
              >
                Issuance Escrow
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvestorIssuanceEscrowTable />
            </AccordionDetails>
          </Accordion> */}

          {/* <Accordion
            sx={{
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              borderRadius: '6px',
              px: 4,
              py: 1,
              mt: 4
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold'
                }}
              >
                Redemption Escrow
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvestorRedemptionEscrowTable />
            </AccordionDetails>
          </Accordion> */}

          <Accordion
            sx={{
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              borderRadius: '6px',
              px: 4,
              py: 1,
              mt: 4
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold'
                }}
              >
                Redemptions
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <InvestorViewRedemption />
            </AccordionDetails>
          </Accordion>

          {/* Payment Transaction */}
          {/* <Accordion
            sx={{
              boxShadow: 'none',
              border: '1px solid #D2D2D2',
              borderRadius: '6px',
              px: 4,
              py: 1,
              mt: 4
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                sx={{
                  fontSize: '1.125rem',
                  fontWeight: 'bold'
                }}
              >
                Token Payments
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PaymentTxnTable />
            </AccordionDetails>
          </Accordion> */}
        </Container>
      </Page>
    </>
  );
};

export default InvestorDashboard;
