import {
  Container,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Card,
  CardContent,
  TableBody,
  Button,
  Typography,
  Chip
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import tokenTerms from '../../mocks/TokenTerms.json';
import ViewAllotmentTable from 'components/viewAllotment/ViewAllotmentTable';

import { useBusinessEntityState, useWeb3, useAuthStateShared } from 'state';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { onlyIssuer } from 'helpers/rbac';
import ReadOnlyNotifier from 'components/ReadOnlyNotifier';
import ViewRedemptionsTables from 'components/viewAllotment/ViewRedemptionsTables';
import TokenTable from 'components/issuerDashboard/TokenTable';
import TokenSummaryModal from 'components/TokenSummaryModal';

const BTLViewAllotment = () => {
  const {
    currentToken: token,
    redeemAllotmentsList,
    fetchTokenSummaryData
  } = useBusinessEntityState();
  const { batchRedeem } = useWeb3();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { role } = useAuthStateShared();
  const [loadingState, setLoadingState] = useState(false);

  const [opentokenSummaryModal, setOpenTokenSummaryModal] = useState(false);
  const handleOpenTokenSummaryModal = () => setOpenTokenSummaryModal(true);
  const handleCloseTokenSummaryModal = () => setOpenTokenSummaryModal(false);
  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" pageHead="" />

      {/* Token Table */}
      <TokenTable currToken={token} />
      {!onlyIssuer(role) && <ReadOnlyNotifier userRole={role} />}

      {/* View Allotment  Table */}
      <Box sx={{ mt: 4 }}>
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Typography
            variant="body2"
            color="initial"
            sx={{
              mt: 4,
              mb: 2,
              fontSize: '1.125rem',
              fontWeight: 'bold'
            }}
          >
            Allotments
          </Typography>
        </Box>
        <Card>
          <CardContent>
            <ViewAllotmentTable />
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default BTLViewAllotment;
