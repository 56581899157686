import apothemDeployment from '@yodaplus/dapps-lib/contracts/apothem.json';
import mainnetDeployment from '@yodaplus/dapps-lib/contracts/mainnet.json';

const buildNetworkConfig = (contractsDeployment, base) => {
  const { address: tokenFactoryAddress, abi: tokenFactoryAbi } =
    contractsDeployment.contracts.TokenFactory;

  return {
    ...base,
    tokenFactoryAddress,
    tokenFactoryAbi
  };
};

const NETWORKS = [
  buildNetworkConfig(mainnetDeployment, {
    id: 50,
    name: 'XDC Mainnet',
    rpcUrl: `${process.env.REACT_APP_MAINNET_URL}`,
    // rpcUrl: 'https://rpc.xinfin.yodaplus.net',
    deploymentUrl: 'https://tokenization.yodaplus.net/'
  }),
  buildNetworkConfig(apothemDeployment, {
    id: 51,
    name: 'XDC Apothem Testnet',
    // rpcUrl:
    //   'https://rpc.ankr.com/xdc_testnet/3aef018939211c8c17cfa08f9a25244f7d22c10598df75464550ef6b9da9a2cb',
    // rpcUrl: 'https://erpc.apothem.network',
    rpcUrl: `${process.env.REACT_APP_APOTHEM_URL}`,
    // rpcUrl: 'https://rpc-apothem.xinfin.yodaplus.net',
    // rpcUrl: 'https://rpc.ankr.com/xdc_testnet',
    deploymentUrl: 'https://tokenization-apothem.yodaplus.net/'
  })
];

export default NETWORKS;
