import { Container, Box, Typography, Card, CardContent, TextField, Grid } from '@mui/material';
import DateAdapter from '@mui/lab/AdapterDateFns';
import { DesktopDatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { useState, useEffect } from 'react';
import PaginationTable from 'components/tables/PaginationTable';
import { format } from 'date-fns';
import { CartesianGrid, Legend, Line, LineChart, XAxis, YAxis, Tooltip } from 'recharts';
import { useBusinessEntityState, useHttpApi } from 'state';
import PDFDownloadIcon from 'assets/images/pdf-icon.png';
import CSVDownloadIcon from 'assets/images/csv-icon.png';
import { useSnackbar } from 'notistack';
import { saveAs } from 'file-saver';

const NAVReports = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { getTokenNavHistory, downloadTokenNavHistoryReport, getTokenNavHistoryReport } =
    useHttpApi();
  const { currentToken } = useBusinessEntityState();
  const [navData, setNavData] = useState([]);
  const [data, setData] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [page, setPage] = useState(1);
  const queryParams = `?page=${page}&from=${fromDate || ''}&to=${toDate || ''}`;
  const [totalCount, setTotalCount] = useState(0);
  const [showError, setShowError] = useState(false);
  const [navChartData, setNavChartData] = useState([]);

  const fetchTokenNavHistory = async () => {
    if (!fromDate && !toDate) {
      setShowError(true);
      return;
    }
    const res = await getTokenNavHistoryReport(currentToken?.token_id, queryParams);
    console.log(res);
    setNavData(res.results);
    setTotalCount(res.count);
  };

  const fetchTokenNavHistoryChartData = async () => {
    if (!fromDate && !toDate) {
      setShowError(true);
      return;
    }
    const res = await getTokenNavHistory(currentToken?.token_id, queryParams);
    console.log(res);
    setNavChartData(res);
  };

  const downloadNavHistoryReport = async (docFormat) => {
    if (!fromDate || !toDate) {
      enqueueSnackbar('Please select a date range', { variant: 'error' });
      return;
    }
    const reportResponse = await downloadTokenNavHistoryReport(
      `?token_id=${currentToken?.token_id}&start_date=${fromDate}&end_date=${toDate}&doc_format=${docFormat}`
    );
    const blob = new Blob([reportResponse], {
      type: docFormat === 'pdf' ? 'application/pdf' : 'text/csv'
    });
    saveAs(blob, `Token_NAV_History.${docFormat}`);
  };

  // UseEffects

  useEffect(() => {
    if (fromDate && toDate) {
      fetchTokenNavHistory();
      fetchTokenNavHistoryChartData();
    }
  }, [page]);

  const headCells = [
    {
      accessor: 'date',
      Header: 'Date',
      show: true
    },
    {
      accessor: 'NAV',
      Header: 'Token NAV ($)',
      show: true,
      width: 200
    }
  ];

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Card>
        <CardContent>
          {/* Filter */}
          <Grid container spacing={1} sx={{ width: '100%' }}>
            <Grid item lg={3} md={4} sm={8} xs={8}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  label="From Date *"
                  fullWidth
                  size="small"
                  inputFormat="MM-dd-yyyy"
                  value={fromDate}
                  onChange={(e) => {
                    console.log(e);
                    setFromDate(format(e, 'yyyy-MM-dd'));
                  }}
                  renderInput={(params) => (
                    <TextField
                      FormHelperTextProps={{ sx: { color: 'red' } }}
                      fullWidth
                      size="small"
                      {...params}
                      helperText={showError && !fromDate && 'Select a date'}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item lg={3} md={4} sm={8} xs={8}>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  label="To Date *"
                  fullWidth
                  size="small"
                  inputFormat="MM-dd-yyyy"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(format(e, 'yyyy-MM-dd'));
                  }}
                  renderInput={(params) => (
                    <TextField
                      FormHelperTextProps={{ sx: { color: 'red' } }}
                      fullWidth
                      size="small"
                      {...params}
                      helperText={showError && !toDate && 'Select a date'}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>

            <Grid item lg={1} md={1} sm={4} xs={4}>
              <LoadingButton
                disabled={!fromDate && !toDate}
                variant="gradient"
                loadingPosition="start"
                onClick={() => {
                  fetchTokenNavHistory();
                  fetchTokenNavHistoryChartData();
                }}
              >
                Filter
              </LoadingButton>
            </Grid>

            {/* Download Icon */}
            <Grid item lg={1} md={1} sm={1} xs={1} sx={{ ml: 4 }}>
              <Box
                sx={{
                  height: '2.5rem',
                  width: '2.5rem',
                  borderRadius: '0.2rem',
                  backgroundColor: '#fff',
                  color: '#000',
                  boxShadow: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
                onClick={async () => {
                  await downloadNavHistoryReport('pdf');
                }}
              >
                <Box
                  component="img"
                  sx={{
                    p: 1
                  }}
                  src={PDFDownloadIcon}
                />
              </Box>
            </Grid>

            <Grid item lg={1} md={1} sm={1} xs={1}>
              <Box
                sx={{
                  height: '2.5rem',
                  width: '2.5rem',
                  borderRadius: '0.2rem',
                  backgroundColor: '#fff',
                  color: '#000',
                  boxShadow: 4,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
                onClick={async () => {
                  await downloadNavHistoryReport('csv');
                }}
              >
                <Box
                  component="img"
                  sx={{
                    p: 1
                  }}
                  src={CSVDownloadIcon}
                />
              </Box>
            </Grid>
          </Grid>
          {/* Table */}
          <PaginationTable
            handlePageUpdate={setPage}
            tableTitle="Token NAV History"
            columns={headCells}
            refreshFunction={fetchTokenNavHistory}
            data={navData}
            setData={setNavData}
            updateMyData={updateMyData}
            skipPageReset={skipPageReset}
            count={totalCount}
          />
        </CardContent>
      </Card>
      <Card sx={{ mt: 4 }}>
        <CardContent>
          <Box sx={{ textAlign: 'center', my: 2 }}>
            <Typography>BTL Token NAV ($) </Typography>
          </Box>
          <LineChart
            width={730}
            height={250}
            data={navChartData}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="top" align="right" height={36} />
            <Line type="monotone" dataKey="NAV" stroke="#8884d8" />
          </LineChart>
        </CardContent>
      </Card>
    </Container>
  );
};

export default NAVReports;
