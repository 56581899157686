import React from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  Divider,
  ListItemText
} from '@mui/material';
import { useNavigate } from 'react-router';
import { useBusinessEntityState, useSubscriptionState, useHttpApi, useAppState } from 'state';
import { useSnackbar } from 'notistack';
import priceIcon from 'assets/images/price_vector.png';
import cryptoAssetTxn from '../../assets/images/masterdata-dashboard/cryptoAssetTxn.svg';
import { Ellipsis } from 'helpers/Ellipsis';

const TokenSubCard = (props) => {
  const { setSubsToken, setToken, setCurrentTokenRuleDetails } = useBusinessEntityState();
  const { getRuleTemplateDetailedByToken } = useHttpApi();
  const { getSubscriberDetails } = useSubscriptionState();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { throwErrorMessage } = useAppState();

  // Function to split the float value into integer and decimal parts
  const formatFloatValue = (value) => {
    const floatValue = parseFloat(value);
    const integerPart = Math.floor(floatValue);
    const decimalPart = (floatValue - integerPart).toFixed(2).substring(2); // Extract decimal part

    return { integerPart, decimalPart };
  };

  const { integerPart, decimalPart } = formatFloatValue(props.token_issue_price);

  const CardFieldForPrice = ({ label, value }) => {
    return (
      <ListItemText
        sx={{
          mt: '16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        primary={label}
        secondary={value}
        primaryTypographyProps={{
          fontSize: '0.62rem',
          color: '#8a8a8a',
          textAlign: 'center'
        }}
        secondaryTypographyProps={{
          fontSize: '0.875rem',
          color: '#000',
          textAlign: 'center'
        }}
      />
    );
  };

  return (
    <Card elevation={5}>
      <CardContent sx={{ m: 0, p: 0 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <Typography
            sx={{
              m: 2,
              p: 0,
              fontWeight: 600
            }}
            variant="h6"
          >
            {Ellipsis(props.token_symbol, '12rem')}
          </Typography>
          <Box
            component="img"
            sx={{
              height: '2rem',
              mr: 2
            }}
            src={cryptoAssetTxn}
          />
        </Box>
        <Divider
          sx={{
            width: '100%',
            backgroundColor: '#f5f8fa',
            border: '2px solid #f5f8fa'
          }}
        />
        <Grid container>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardFieldForPrice label="Token Name" value={Ellipsis(props.token_name)} />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardFieldForPrice
              label="Currency"
              value={Ellipsis(props.token_currency.currency_code)}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardFieldForPrice 
            label="Issue Start Date" 
            value={props.token_start_date} />
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <CardFieldForPrice
              label="Token isssue price (USD)"
              value={Ellipsis(props.token_issue_price)}
            />
          </Grid>
        </Grid>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
        >
          <CardFieldForPrice label="Token NAV" value={props.token_nav} />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            gap: 1,
            mt: 2
          }}
        >
          {/* <Box component="img" src={priceIcon} />
          <Typography variant="body2" color="textSecondary">
            Price
          </Typography> */}
        </Box>
        <Grid
          container
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          marginTop={2}
        >
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <Button
              size="medium"
              variant="contained"
              onClick={async () => {
                try {
                  if (!props.account) {
                    enqueueSnackbar('Please login using your Web3 Wallet to subscribe', {
                      variant: 'error'
                    });
                  } else {
                    const res = await getSubscriberDetails(props.account, props.token_id);
                    console.log({ res });
                    if (res) {
                      setToken(props);
                      setSubsToken(props);
                      const response = await getRuleTemplateDetailedByToken(props.token_id);
                      setCurrentTokenRuleDetails(response);
                      // Add the conditional logic here - redirect to Safi details page or view token page.
                      if(props.issue_category_id.issue_category_name === 'Long Term Bond'){
                        navigate(`/subscription/investment-information`);
                      }
                      else{
                        navigate(`/subscription/view-token`);
                      }
                    }
                  }
                } catch (e) {
                  throwErrorMessage(e);
                }
              }}
            >
              More Info
            </Button>
          </Grid>
          <Grid item lg={6} md={6} xs={6} sm={6}>
            <Button
              variant="contained"
              size="medium"
              disabled={!(props.isKycDone && props.isAuthDone)}
              onClick={async () => {
                try {
                  navigate(`/subscription/form/${props.deployment_address}`);
                } catch (e) {
                  throwErrorMessage(e);
                }
              }}
            >
              Invest
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TokenSubCard;
