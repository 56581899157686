import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useAuthState, useAuthStateShared, useCoreTableState } from 'state';
import { useIsOwner } from 'helpers/rbac';

const AuthRequired = ({ children }) => {
  const { isAuthorized, initAuth } = useAuthState();
  const { user, role } = useAuthStateShared();
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchOrganizations, orgnizations } = useCoreTableState();
  const isOwner = useIsOwner();

  // useEffect(() => {
  //   fetchOrganizations();
  // }, []);

  useEffect(() => {
    fetchOrganizations();
    initAuth();
    if (user?.xinfin_address?.role === 'INVESTOR') {
      const search = location;
      const params = new URLSearchParams(search.search);
      const query = params.get('authorization_code');
      console.log('inside auth check  -- -  - query - - -', query);
      if (query) {
        navigate(`/subscription?authorization_code=${query}`);
      } else {
        navigate('/subscription');
      }
    }
    // if (user?.id && isOwner && orgnizations[0].is_updated === false) {
    //   navigate('/home/add-organization');
    // } else if (user && !user.is_password_changed) {
    //   navigate('/home/change-password');
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isOwner]);

  return isAuthorized ? children : null;
};

export default AuthRequired;
