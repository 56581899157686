import EmailIcon from '@mui/icons-material/Email';

const MailComponent = ({ email, subject = '', body = '', children }) => {
  let params = subject || body ? '?' : '';
  if (subject) params += `subject=${encodeURIComponent(subject)}`;
  if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

  return (
    <a href={`mailto:${email}${params}`}>
      <EmailIcon
        sx={{
          fontSize: '2rem',
          p: 1,
          m: 0,
          backgroundColor: '#F5F8FA',

          mt: '0.1rem',
          color: 'black'
        }}
      />
    </a>
  );
};

export default MailComponent;
