import { Box, Container, Grid, Tab, Typography } from '@mui/material';
import TokenTable from 'components/issuerDashboard/TokenTable';
import BTLSubscriptionTable from 'components/issuerDashboard/BTLSubscriptionTable';
import Breadcrumbs from 'components/Breadcrumbs';
import { useAuthStateShared, useBusinessEntityState } from 'state';
import TokenActionsCard from 'components/issuerDashboard/TokenActionsCard';
import allotments from 'assets/images/dashboard-icon/allotments.svg';
import redemptions from 'assets/images/dashboard-icon/redemptions.svg';
import cashTxn from 'assets/images/dashboard-icon/cashTxn.svg';
import KYCAMLCTF from 'assets/images/dashboard-icon/KYCAMLCTF.svg';
import docManagement from 'assets/images/dashboard-icon/docManagement.svg';
import escrowSettlement from 'assets/images/dashboard-icon/escrowSettlement.svg';
import auditReport from 'assets/images/dashboard-icon/auditReport.svg';
import newSubscription from 'assets/images/dashboard-icon/newSubscription.svg';
import { useNavigate } from 'react-router';
import enterpriseApp from 'assets/images/dashboard-icon/enterpriseApp.svg';
import { TabPanel } from '@mui/lab';
import { useState } from 'react';
import TabList from '@mui/lab/TabList';
import TabContext from '@mui/lab/TabContext';
import NAVReports from './NAVReports';
import TDATransactionReport from './TDATransactionReport';
import BTLTransactionReport from './BTLTransactionReport';
import CustomerTransactionReport from './CustomerTransactionReport';

const BTLReports = () => {
  const { currentToken: token } = useBusinessEntityState();
  const { role } = useAuthStateShared();
  const navigate = useNavigate();
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" pageHead="" />
      <Container sx={{ backgroundColor: 'white', mt: 3 }}>
        <Box>
          <Grid container spacing={3}>
            <Grid item lg={12} md={12} sm={9} sx={{ mt: 2 }}>
              <Grid container spacing={3}>
                <Box
                  sx={{
                    p: 3,
                    width: '100%',
                    typography: 'body1'
                  }}
                >
                  <TabContext
                    value={value}
                    sx={{
                      maxWidth: { xs: 320, sm: 480 },
                      bgcolor: 'background.paper'
                    }}
                  >
                    <Box
                      sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        width: '100%',
                        bgcolor: 'background.paper'
                      }}
                    >
                      <TabList onChange={handleChange} aria-label="lab API tabs example">
                        <Tab label="Customer Transaction Report" value="1" />
                        <Tab label="BTL Entity Transaction Report" value="2" />
                        <Tab label="TDA Fee Collection Report" value="3" />
                        <Tab label="NAV History Reports" value="4" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <CustomerTransactionReport />
                    </TabPanel>
                    <TabPanel value="2">
                      <BTLTransactionReport />
                    </TabPanel>
                    <TabPanel value="3">
                      <TDATransactionReport />
                    </TabPanel>
                    <TabPanel value="4">
                      <NAVReports />
                    </TabPanel>
                  </TabContext>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Container>
  );
};

export default BTLReports;
