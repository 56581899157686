import {
  Container,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Card,
  CardContent,
  TableBody,
  Button,
  Typography,
  Chip
} from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import tokenTerms from '../../mocks/TokenTerms.json';
import ViewAllotmentTable from 'components/viewAllotment/ViewAllotmentTable';

import { useBusinessEntityState, useWeb3, useAuthStateShared } from 'state';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import React, { useState } from 'react';
import { onlyIssuer } from 'helpers/rbac';
import ReadOnlyNotifier from 'components/ReadOnlyNotifier';
import ViewRedemptionsTables from 'components/viewAllotment/ViewRedemptionsTables';
import TokenTable from 'components/issuerDashboard/TokenTable';
import TokenSummaryModal from 'components/TokenSummaryModal';

const BTLViewRedemption = () => {
  const { currentToken: token } = useBusinessEntityState();

  const { role } = useAuthStateShared();

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" pageHead="" />

      {/* Token Table */}
      <TokenTable currToken={token} />
      {!onlyIssuer(role) && <ReadOnlyNotifier userRole={role} />}

      {/* Redemptions Table */}
      <Box sx={{ mt: 2 }}>
        <Typography
          variant="body2"
          color="initial"
          sx={{
            mt: 4,
            mb: 2,
            fontSize: '1.125rem',
            fontWeight: 'bold'
          }}
        >
          Redemptions
        </Typography>
        <Card>
          <CardContent>
            <ViewRedemptionsTables />
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export default BTLViewRedemption;
