import React, { useState, useEffect } from 'react';
import { Button, Modal, Box, Typography, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHttpApi } from 'state';
import FactCheckIcon from '@mui/icons-material/FactCheck';
// Modal component to show Plaid account information
const IdentityInformationModal = ({ investorId }) => {
  const [accountInfo, setAccountInfo] = useState(null);
  const [accountInfoNotAvailable, setAccountInfoNotAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState();
  const { getKycDetailInformation } = useHttpApi();

  const handleClose = () => {
    setAccountInfo(null);
    setAccountInfoNotAvailable(false);
    setModalOpen(false);
  };

  const handleOpen = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    if (modalOpen) {
      fetchAccountInfo();
    }
  }, [modalOpen]);

  const fetchAccountInfo = async () => {
    setLoading(true);
    try {
      // Replace this with actual API call logic to Plaid
      const response = await getKycDetailInformation(investorId);
      if (response.status === 'success') {
        setAccountInfo(response.data);
      } else {
        setAccountInfoNotAvailable(true);
      }
    } catch (error) {
      console.error('Error fetching account info:', error);
      setAccountInfoNotAvailable(true);
    } finally {
      setLoading(false);
    }
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4
  };

  const street = accountInfo?.address?.street || '';
  const street2 = accountInfo?.address?.street2 ? `${accountInfo?.address.street2}, ` : '';
  const city = accountInfo?.address?.city || '';
  const region = accountInfo?.address?.region || '';
  const postalCode = accountInfo?.address?.postal_code || '';
  const country = accountInfo?.address?.country || '';

  const fullAddress = `${street}${street2}${city}, ${region}, ${postalCode}, ${country}`;

  return (
    <>
      <FactCheckIcon
        onClick={handleOpen}
        sx={{ fontSize: '2rem', p: 1, m: 0, backgroundColor: '#F5F8FA', mt: '0.2rem',cursor: 'pointer' }}
      />

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle} mt={2}>
          {loading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
              <Typography>Loading...</Typography>
            </Box>
          )}
          {!loading && accountInfoNotAvailable && (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
              <Typography>Account Information Is Not Available</Typography>
            </Box>
          )}

          {!loading && accountInfo && (
            // <>
            //     <Typography><strong>Name:</strong> {accountInfo.name}</Typography>
            //     <Typography><strong>Phone Number:</strong> {accountInfo.phone_number}</Typography>
            //     <Typography><strong>ID number:</strong> {accountInfo.id_number}</Typography>
            //     <Typography><strong>Date of Birth:</strong> {accountInfo.date_of_birth}</Typography>
            //     <Typography><strong>Address:</strong> {accountInfo.address}</Typography>
            // </>

            <>
              <Typography>
                <strong>Name:</strong> {accountInfo.name?.given_name}{' '}
                {accountInfo.name?.family_name}
              </Typography>
              <Typography>
                <strong>Phone Number:</strong> {accountInfo.phone_number}
              </Typography>
              <Typography>
                <strong>ID Number:</strong> {accountInfo.id_number?.value} -{' '}
                {accountInfo.id_number?.type}
              </Typography>
              <Typography>
                <strong>Date of Birth:</strong> {accountInfo.date_of_birth?.toString()}
              </Typography>
              <Typography>
                <strong>Address:</strong> {fullAddress}
              </Typography>
            </>
          )}

          {!loading && !accountInfo && !accountInfoNotAvailable && (
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
              <Button variant="contained" color="primary" onClick={fetchAccountInfo}>
                Fetch Identity Info
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default IdentityInformationModal;
