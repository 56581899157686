import React, { useState, useEffect } from 'react';
import { Grid, TextField, FormLabel, Box, Alert, Divider, Typography } from '@mui/material';
import { useBusinessEntityState, useHttpApi, useWeb3, useAppState, useCoreTableState } from 'state';
import LoadingActionButton from 'helpers/LoadingActionButton';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

const UpdateTokenTerms = () => {
  const { tokenById, currentToken, setCurrentTokenById, setToken, fetchTokens } =
    useBusinessEntityState();
  const { throwErrorMessage } = useAppState();

  const { enqueueSnackbar } = useSnackbar();
  const [newTokenNav, setNewTokenNav] = useState('');
  const [newPlatformFees, setNewPlatformFees] = useState('');

  const { editToken, getTokenDetailsById, getCanUpdateTokenTerms } = useHttpApi();
  const [newTokenSupply, setNewTokenSupply] = useState('');
  const navigate = useNavigate();
  const { setMaxSupply, setNav, getTotalSupply } = useWeb3();
  const { fetchCurrentToken } = useBusinessEntityState();

  const [totalSupply, setTotalSupply] = useState(0);
  const UpdateData = async (data, msg, status) => {
    try {
      const _editToken = await editToken(tokenById.token_id, data);
      if (_editToken.token_id) {
        setCurrentTokenById(_editToken);
        const res = await getTokenDetailsById(_editToken.token_id);
        if (res.token_id) {
          setToken(res);
        }
        enqueueSnackbar(`${msg}`, {
          variant: `${status}`
        });
        // navigate('/home/token-dashboard');
        fetchTokens();
      }
    } catch (e) {
      throwErrorMessage(e);
    }
  };

  const getCurrentTotalSupply = async () => {
    const _totalSupply = await getTotalSupply(currentToken?.deployment_address);
    setTotalSupply(_totalSupply);
  };

  const canUpdateTokenTerms = async () => {
    const res = await getCanUpdateTokenTerms(currentToken?.token_id);

    if (res) {
      return res.can_update;
    }
    return false;
  };
  useEffect(() => {
    getCurrentTotalSupply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentToken]);
  return (
    <>
      <Grid container spacing={3} sx={{ width: '100%', pl: 3, mt: 1 }}>
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel>Token NAV </FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              value={currentToken.token_nav}
              fullWidth
              inputProps={{
                readOnly: true
              }}
              size="small"
              autoComplete="off"
              type="number"
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <FormLabel>New Token NAV</FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              fullWidth
              size="small"
              autoComplete="off"
              type="number"
              value={newTokenNav}
              onChange={(e) => setNewTokenNav(e.target.value)}
            />
            {Number(newTokenNav) > 1.1 * Number(currentToken.token_nav) && (
              <Typography sx={{ color: 'orange', fontSize: '0.7rem' }}>
                Warning: New NAV is greater than 10% of existing NAV
              </Typography>
            )}
          </Grid>

          <Grid item lg={2} md={2} sm={12} xs={12}>
            <LoadingActionButton
              sx={{
                width: '7.5rem',
                height: '2.5rem',
                mt: '2.188rem'
                // ml: '1rem'
              }}
              variant="gradient"
              loadingPosition="start"
              onClick={async () => {
                try {
                  if (!canUpdateTokenTerms()) {
                    enqueueSnackbar(
                      'Cannot perform this action as active transactions are pending',
                      {
                        variant: 'error'
                      }
                    );
                    return;
                  }
                  if (newTokenNav === '') {
                    enqueueSnackbar('Token Nav is required', {
                      variant: 'error'
                    });
                  } else {
                    const res = await setNav(currentToken.deployment_address, Number(newTokenNav));
                    enqueueSnackbar('Token Nav update in progress', {
                      variant: 'success'
                    });
                    setNewTokenNav('');
                  }
                } catch (e) {
                  throwErrorMessage(e);
                }
              }}
            >
              Update
            </LoadingActionButton>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1 }}>
            <FormLabel>Current Platform Fees (%)</FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              value={currentToken.platform_fees}
              fullWidth
              inputProps={{
                readOnly: true
              }}
              size="small"
              autoComplete="off"
              type="number"
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1 }}>
            <FormLabel>New Platform Fees (%)</FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              fullWidth
              size="small"
              autoComplete="off"
              type="number"
              value={newPlatformFees}
              onChange={(e) => setNewPlatformFees(e.target.value)}
            />
          </Grid>
          <Grid item lg={2} md={2} sm={12} xs={12} sx={{ mt: 1 }}>
            <LoadingActionButton
              sx={{
                width: '7.5rem',
                height: '2.5rem',
                mt: '2.188rem'
                // ml: '1rem'
              }}
              variant="gradient"
              loadingPosition="start"
              onClick={async () => {
                try {
                  if (!canUpdateTokenTerms()) {
                    enqueueSnackbar(
                      'Cannot perform this action as active transactions are pending',
                      {
                        variant: 'error'
                      }
                    );
                    return;
                  }
                  if (newPlatformFees === '') {
                    enqueueSnackbar('New Platform fees value is required', { variant: 'error' });
                  }
                  const data = {};
                  data.platform_fees = newPlatformFees;
                  await UpdateData(data, 'Platform fees updated sucessfully', 'success');
                  const res = await getTokenDetailsById(currentToken.token_id);
                  fetchCurrentToken(res);
                  setNewPlatformFees('');
                } catch (e) {
                  throwErrorMessage(e);
                }
              }}
            >
              Update
            </LoadingActionButton>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <FormLabel>Max Supply</FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              fullWidth
              size="small"
              autoComplete="off"
              type="number"
              inputProps={{
                readOnly: true
              }}
              value={currentToken.total_supply}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <FormLabel>New Max Supply</FormLabel>
            <TextField
              sx={{ mt: 1.5 }}
              fullWidth
              size="small"
              autoComplete="off"
              type="number"
              value={newTokenSupply}
              onChange={(e) => {
                setNewTokenSupply(e.target.value);
              }}
            />
            {newTokenSupply && (
              <Typography sx={{ color: 'grey', fontSize: '10px' }}>
                current total supply : {totalSupply}
              </Typography>
            )}
          </Grid>

          <Grid item lg={2} md={2} sm={12} xs={12} sx={{ mt: 1.5 }}>
            <LoadingActionButton
              sx={{
                width: '7.5rem',
                height: '2.5rem',
                mt: '2.188rem'
              }}
              variant="gradient"
              loadingPosition="start"
              onClick={async () => {
                console.log(newTokenSupply, totalSupply, totalSupply >= newTokenSupply);
                if (!canUpdateTokenTerms()) {
                  enqueueSnackbar('Cannot perform this action as active transactions are pending', {
                    variant: 'error'
                  });
                  return;
                }
                if (newTokenSupply === '') {
                  enqueueSnackbar('Token Supply is required', {
                    variant: 'error'
                  });
                } else if (Number(totalSupply) >= Number(newTokenSupply)) {
                  console.log(
                    '🚀 ~ onClick={ ~ totalSupply:',
                    typeof totalSupply,
                    typeof newTokenSupply
                  );
                  return enqueueSnackbar('New Max Supply cannot be less than the total supply', {
                    variant: 'error'
                  });
                } else {
                  try {
                    const res = await setMaxSupply(
                      currentToken.deployment_address,
                      Number(newTokenSupply)
                    );
                    enqueueSnackbar('Token Max Supply update in progress', {
                      variant: 'success'
                    });
                    setNewTokenSupply('');
                  } catch (e) {
                    throwErrorMessage(e);
                  }
                }
              }}
            >
              Update
            </LoadingActionButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default UpdateTokenTerms;
