import React from 'react';
import {
  Box,
  Table,
  TableHead,
  TableContainer,
  TableRow,
  TableCell,
  TableBody
} from '@mui/material';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  tablecontainer: {
    [theme.breakpoints.down('sm')]: {
      width: 450
    },
    [theme.breakpoints.between('md', 'sm')]: {
      width: 700
    },
    [theme.breakpoints.between('lg', 'md')]: {
      width: 1050
    }
  }
}));
// eslint-disable-next-line no-unused-vars
const InvestorTokenTable = (props) => {
  const classes = useStyles();
  return (
    <Box
      sx={{
        border: '1px solid #E8E4E4',
        borderRadius: '6px'
      }}
    >
      <TableContainer className={classes.tablecontainer}>
        <Table>
          <TableHead>
            <TableRow sx={{ fontWeight: '500' }}>
              <TableCell sx={{ color: '#8E8EA7' }}>Name</TableCell>
              <TableCell sx={{ color: '#8E8EA7' }}>Symbol</TableCell>
              <TableCell sx={{ color: '#8E8EA7' }}>Issue Type</TableCell>
              <TableCell sx={{ color: '#8E8EA7' }}>Currency</TableCell>
              <TableCell sx={{ color: '#8E8EA7' }}>Issue Size</TableCell>
              <TableCell sx={{ color: '#8E8EA7' }}>Price</TableCell>
              <TableCell sx={{ color: '#8E8EA7' }}>Min. Subs. (Token)</TableCell>
              <TableCell sx={{ color: '#8E8EA7' }}>Supply</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props && (
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>{props?.token_name}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{props?.token_symbol}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  {props?.issue_type_id?.issue_type_name}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>
                  {props?.token_currency?.currency_name}
                </TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{props?.max_issue_size}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{props?.token_nav}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{props?.minimum_subscription}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{props?.total_supply}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default InvestorTokenTable;
