/* eslint-disable react-hooks/exhaustive-deps */
import {
  Container,
  Card,
  Grid,
  CardContent,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  TextField,
  FormLabel,
  Checkbox,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Divider,
  IconButton,
  InputAdornment,
  Box,
  Alert,
  Button
} from '@mui/material';
import { DesktopDatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Page from 'components/Page';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useCoreTableState } from 'state/useCoreTableState';
import {
  useAppState,
  useAuthStateShared,
  useBusinessEntityState,
  useHttpApi,
  useWeb3
} from 'state';
import { useSnackbar } from 'notistack';
import DateAdapter from '@mui/lab/AdapterDateFns';
import {
  format,
  addDays,
  differenceInMonths,
  isAfter,
  isBefore,
  addWeeks,
  addMonths,
  addYears
} from 'date-fns';
import { useNavigate } from 'react-router';
import FocusError from 'components/FocusError';
import { ceil, floor } from 'lodash';

import { ethToXdcAddress, xdcToEthAddress } from 'helpers/web3';
import axios from 'axios';
import AddressFieldTools from 'components/AddressFieldTools';
import { CURRENCY_URL, CURRENCY_API_KEY } from 'config';
import { parseTokenValue, roundToNearest100 } from 'helpers/numbers';
import CreateTokenSchema from 'components/issuerDashboard/TokenValidationSchema';
import AccordionLayout from 'helpers/AccordionLayout';

const CreateToken = () => {
  const {
    issueType,
    issueCategories,
    issueSubCategories,
    paymentTokens,
    paymentTokenCurrencies,
    defaultDataAsPerIssueType,
    defaultTokenValues,
    paymentLegs
  } = useCoreTableState();
  const {
    originators,
    onChainLiquidityPools: pools,
    issuerInvestorMapByIssuerId,
    fetchIssuerInsurerMappingByIssuerId,
    fetchOriginator,
    fetchIssuer,
    issuers
  } = useBusinessEntityState();

  const { user, entity } = useAuthStateShared();
  const [ifProxyToken, setIfProxyToken] = useState(false);

  const { throwErrorMessage } = useAppState();
  const { createToken, getLiquidityPoolsByIssuerId, getPaymentTokenByCurrency } = useHttpApi();
  const [error, setError] = useState('');

  const contractDoc = useRef(null);

  const { enqueueSnackbar } = useSnackbar();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const tmr = new Date() + 1;

  const issueDatehandleChange = (newDate) => {
    try {
      formik.setFieldValue('token_start_date', format(newDate, 'yyyy-MM-dd'));
    } catch (err) {
      console.log(err);
    }
  };
  console.log('issuer', user);
  // Formik
  const formik = useFormik({
    validate: (values) => {
      const errors = {};

      if (!values.contract_doc_hash) {
        errors.contract_doc_hash = 'Token Term sheet is required.';
      }
      if (!values.contract_doc_hash) {
        errors.contract_doc_hash = 'Token Term sheet is required.';
      }

      if (values.contract_doc_hash) {
        if (contractDoc && contractDoc.current.files[0].size > 20971520) {
          errors.contract_doc_hash = 'File size greater than 20MB';
        }
      }

      if (values.minimum_subscription > values.total_supply) {
        errors.minimum_subscription = 'Minimum Subscription should be less than Total Supply';
      }
      return errors;
    },
    initialValues: {
      issuer_id: entity?.issuer_id,
      issue_currency: 'USD',
      token_name: '',
      token_symbol: '',
      total_supply: '',
      token_value: '',
      max_issue_size: '',

      issue_type_id: '',
      issue_category_id: '',
      fund_manager: 'BTL',
      contract_doc_hash: '',
      token_start_date: format(Date.now(), 'yyyy-MM-dd'),
      minimum_subscription: defaultTokenValues.minimum_subscription,
      blockchain: 'XDC Networks',
      token_nav: '',
      platform_fees: '',
      mode_of_payment: 'Cash Transfer Through Bank',
      settlement_cycle: '2',
      use_of_proceeds: ''
    },

    validationSchema: CreateTokenSchema,
    onSubmit: async (data, { resetForm, setErrors }) => {
      console.log('SUBMITTING FORM - - - - -- ');
      console.log('SUBMITTING FORM - - - - -- ');
      try {
        console.log('Token Data - -', data);
        const contractDocFile = contractDoc.current.files[0];
        console.log('Contract Doc File - -- ', contractDocFile);
        data.contract_doc_hash = contractDocFile;

        console.log(JSON.stringify(data, null, ' '));
        await createToken(data);
        formik.setFieldValue('contract_doc_hash', '');
        resetForm();

        navigate('/home');
        enqueueSnackbar('Token created successfully', {
          variant: 'success'
        });
      } catch (e) {
        formik.setFieldValue('contract_doc_hash', '');
        throwErrorMessage(e);
      }
    }
  });

  const { errors, touched, values, handleSubmit, getFieldProps } = formik;

  const [payment, setPayment] = useState([]);

  useEffect(() => {
    if (!entity?.issuer_id) return;
    fetchIssuerInsurerMappingByIssuerId(entity.issuer_id);
    formik.setFieldValue('issuer_id', entity.issuer_id);
  }, [entity]);

  useEffect(() => {
    CalculateTenure();
  }, [formik.values.token_create_date, formik.values.final_maturity_date]);

  const handleTokenValueChange = (e) => {
    // formik.setFieldValue('token_redemption_price', values.token_value);
    formik.setFieldValue('token_nav', formik.values.token_value);
    return values.token_value;
  };
  const CalculateTenure = () => {
    if (formik.values.token_create_date && formik.values.final_maturity_date) {
      const tenure = Number(
        differenceInMonths(
          new Date(formik.values.final_maturity_date),
          new Date(formik.values.token_create_date)
        )
      );
      formik.setFieldValue('tenure_months', tenure);
    }
  };

  const navigate = useNavigate();

  const longTermBondCategoryId = issueCategories.find(
    (category) => category.issue_category_name === 'Long Term Bond'
  )?.id;

  const handleIssueSize = () => {
    const _issueSize = formik.values.total_supply * formik.values.token_value;
    formik.setFieldValue('max_issue_size', _issueSize);
    return _issueSize;
  };

  useEffect(()=>{
    handleIssueSize();
  },[formik.values.total_supply, formik.values.token_value])

  useEffect(() => {
    // Automatically select the single ISSUE TYPE if there's only one option
    if (issueType && issueType.length === 1) {
      formik.setFieldValue('issue_type_id', issueType[0].id);
    }
  }, [issueType]);

  return (
    <>
      <Page title="Create Token">
        <Container sx={{ mt: 3 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="" />
          <Card sx={{ mt: 2 }}>
            <CardContent sx={{ m: 4 }}>
              {error && (
                <Box mb={4}>
                  <Alert severity="error">{error}</Alert>
                </Box>
              )}
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <AccordionLayout
                    defaultExpanded
                    title="Token Header"
                    content={
                      <Grid container spacing={3} sx={{ width: '100%' }}>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                          <FormLabel>Issuer Name</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // defaultValue={issuerId}
                              IconComponent="none"
                              inputProps={{ readOnly: true }}
                              {...getFieldProps('issuer_id')}
                              error={Boolean(touched.issuer_id && errors.issuer_id)}
                              helperText={touched.issuer_id && errors.issuer_id}
                            >
                              {issuers.map((issuer) => {
                                return (
                                  <MenuItem
                                    selected
                                    className="Mui-selected"
                                    key={issuer.issuer_id}
                                    value={issuer.issuer_id}
                                  >
                                    {issuer.issuer_name}
                                  </MenuItem>
                                );
                              })}
                              {entity?.issuer_id && (
                                <MenuItem
                                  selected
                                  className="Mui-selected"
                                  key={entity?.issuer_id}
                                  value={entity?.issuer_id}
                                >
                                  {entity?.issuer_name}
                                </MenuItem>
                              )}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issuer_id && errors.issuer_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Currency</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // placeholder="Token Name"
                            id="issue_currency"
                            inputProps={{ maxLength: 50 }}
                            value="USD"
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Name</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // placeholder="Token Name"
                            id="token_name"
                            inputProps={{ maxLength: 50 }}
                            {...getFieldProps('token_name')}
                            error={Boolean(touched.token_name && errors.token_name)}
                            helperText={touched.token_name && errors.token_name}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Symbol</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // placeholder="XDCYP"
                            {...getFieldProps('token_symbol')}
                            id="token_symbol"
                            inputProps={{ maxLength: 11 }}
                            error={Boolean(touched.token_symbol && errors.token_symbol)}
                            helperText={touched.token_symbol && errors.token_symbol}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Max. Supply</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            // placeholder="100000000"
                            id="total_supply"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                e.preventDefault(); // Prevents incrementing/decrementing with arrow keys
                              }
                            }}
                            onBlur={() => {
                              handleIssueSize();
                            }}
                            {...getFieldProps('total_supply')}
                            error={Boolean(touched.total_supply && errors.total_supply)}
                            helperText={touched.total_supply && errors.total_supply}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Value (USD)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            // placeholder="100000000"
                            id="token_value"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                e.preventDefault(); // Prevents incrementing/decrementing with arrow keys
                              }
                            }}
                            {...getFieldProps('token_value')}
                            onBlur={() => {
                              handleIssueSize();
                              handleTokenValueChange();
                            }}
                            error={Boolean(touched.token_value && errors.token_value)}
                            helperText={touched.token_value && errors.token_value}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Max.Issue Size (USD)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            type="number"
                            size="small"
                            inputProps={{ readOnly: true }}
                            // placeholder="10000000"
                            onKeyDown={(e) => {
                              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                e.preventDefault(); // Prevents incrementing/decrementing with arrow keys
                              }
                            }}
                            {...getFieldProps('max_issue_size')}
                            error={Boolean(touched.max_issue_size && errors.max_issue_size)}
                            helperText={touched.max_issue_size && errors.max_issue_size}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Type</FormLabel>

                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // placeholder="Select Issue Type"
                              {...getFieldProps('issue_type_id')}
                              id="issue_type_id"
                              error={Boolean(touched.issue_type_id && errors.issue_type_id)}
                              helperText={touched.issue_type_id && errors.issue_type_id}
                            >
                              {issueType.map((issueType) => {
                                return (
                                  <MenuItem key={issueType.id} value={issueType.id}>
                                    {issueType.issue_type_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issue_type_id && errors.issue_type_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Category</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              displayEmpty
                              inputProps={{ readOnly: true }}
                              {...getFieldProps('issue_category_id', {
                                initialValue: issueCategories[0].id
                              })}
                              id="issue_category_id"
                              value={issueCategories[0].id}
                            >
                              <MenuItem
                                selected
                                key={issueCategories[0].id}
                                value={issueCategories[0].id}
                              >
                                {issueCategories[0].issue_category_name}
                              </MenuItem>
                              <MenuItem
                                selected
                                key={issueCategories[0].id}
                                value={issueCategories[0].id}
                              >
                                {issueCategories[0].issue_category_name}
                              </MenuItem>
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issue_category_id && errors.issue_category_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Fund Manager</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // placeholder="Token Name"
                            id="fund_manager"
                            inputProps={{ maxLength: 50 }}
                            {...getFieldProps('fund_manager')}
                            error={Boolean(touched.fund_manager && errors.fund_manager)}
                            helperText={touched.fund_manager && errors.fund_manager}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                  <AccordionLayout
                    defaultExpanded
                    title="Token Economics Terms"
                    content={
                      <Grid container spacing={3} sx={{ width: '100%' }}>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                          <FormLabel>Token Term Sheet</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="file"
                            inputRef={contractDoc}
                            inputProps={{ accept: '.pdf' }}
                            {...getFieldProps('contract_doc_hash')}
                            id="contract_doc_hash"
                            error={Boolean(touched.contract_doc_hash && errors.contract_doc_hash)}
                            helperText={touched.contract_doc_hash && errors.contract_doc_hash}
                          />
                          <Grid container sx={{ width: '100%', mt: 2 }}>
                            <Grid item lg={4} md={4} sm={4}>
                              <Typography
                                sx={{
                                  fontSize: '0.688rem',
                                  textAlign: 'left',
                                  color: '#161c2d'
                                }}
                              >
                                Maximum 20MB file size
                              </Typography>
                            </Grid>
                            <Grid item lg={8} md={8} sm={8}>
                              <Typography
                                sx={{
                                  fontSize: '0.688rem',
                                  textAlign: 'right',
                                  color: '#8e8ea7'
                                }}
                              >
                                Accepted File Type .pdf only
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Start Date</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              minDate={new Date()}
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              value={formik.values.token_start_date}
                              onChange={issueDatehandleChange}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('token_start_date')}
                                  id="token_start_date"
                                  error={Boolean(
                                    touched.token_start_date && errors.token_start_date
                                  )}
                                  helperText={touched.token_start_date && errors.token_start_date}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Minimum Subscription Qty</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            type="number"
                            autoComplete="off"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                e.preventDefault(); // Prevents incrementing/decrementing with arrow keys
                              }
                            }}
                            // placeholder="1"
                            {...getFieldProps('minimum_subscription')}
                            id="minimum_subscription"
                            error={Boolean(
                              touched.minimum_subscription && errors.minimum_subscription
                            )}
                            helperText={touched.minimum_subscription && errors.minimum_subscription}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Blockchain</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            // placeholder="Token Name"
                            id="blockchain"
                            inputProps={{ maxLength: 50 }}
                            {...getFieldProps('blockchain')}
                            error={Boolean(touched.blockchain && errors.blockchain)}
                            helperText={touched.blockchain && errors.blockchain}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token NAV (USD)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="1000"
                            fullWidth
                            size="small"
                            // onWheel={(e) => {
                            //   e.target.blur();
                            // }}
                            // onKeyDown={(e) => {
                            //   if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                            //     e.preventDefault(); // Prevents incrementing/decrementing with arrow keys
                            //   }
                            // }}
                            autoComplete="off"
                            type="number"
                            inputProps={{ readOnly: true }}
                            {...getFieldProps('token_nav')}
                            error={Boolean(touched.token_nav && errors.token_nav)}
                            helperText={touched.token_nav && errors.token_nav}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Platform Fees (%)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="1000"
                            fullWidth
                            size="small"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            autoComplete="off"
                            type="number"
                            // inputProps={{ readOnly: true }}
                            {...getFieldProps('platform_fees')}
                            error={Boolean(touched.platform_fees && errors.platform_fees)}
                            helperText={touched.platform_fees && errors.platform_fees}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Mode Of Payment</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="1000"
                            fullWidth
                            size="small"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            autoComplete="off"
                            type="text"
                            inputProps={{ readOnly: true }}
                            {...getFieldProps('mode_of_payment')}
                            error={Boolean(touched.mode_of_payment && errors.mode_of_payment)}
                            helperText={touched.mode_of_payment && errors.mode_of_payment}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Settlement Cycle (T + Days)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="1000"
                            fullWidth
                            size="small"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            autoComplete="off"
                            type="number"
                            // inputProps={{ readOnly: true }}
                            {...getFieldProps('settlement_cycle')}
                            error={Boolean(touched.settlement_cycle && errors.settlement_cycle)}
                            helperText={touched.settlement_cycle && errors.settlement_cycle}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Use of Token Sale Proceeds</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            autoComplete="off"
                            type="text"
                            multiline
                            rows={4}
                            // inputProps={{ readOnly: true }}
                            {...getFieldProps('use_of_proceeds')}
                            error={Boolean(touched.use_of_proceeds && errors.use_of_proceeds)}
                            helperText={touched.use_of_proceeds && errors.use_of_proceeds}
                          />
                        </Grid>
                      </Grid>
                    }
                  />

                  <Box display="flex" justifyContent="flex-end">
                    <Button
                      variant="outlined"
                      color="secondary"
                      sx={{
                        width: '7.5rem',
                        height: '2.5rem',
                        mr: 2,
                        mt: 2
                      }}
                      onClick={() => {
                        navigate('/home');
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      sx={{
                        width: '7.5rem',
                        height: '2.5rem',

                        mt: 2
                      }}
                      variant="gradient"
                      loadingPosition="start"
                      id="create-token"
                      type="submit"
                    >
                      Save
                    </LoadingButton>
                  </Box>
                  <FocusError />
                </Form>
              </FormikProvider>
            </CardContent>
          </Card>
        </Container>
      </Page>
    </>
  );
};
export default CreateToken;
