import {
  Container,
  Card,
  Grid,
  CardContent,
  Typography,
  TextField,
  FormLabel,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
  Box,
  Alert,
  Button,
  IconButton,
  InputAdornment
} from '@mui/material';
import { DesktopDatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import Page from 'components/Page';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import Breadcrumbs from 'components/Breadcrumbs';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { useCoreTableState } from 'state/useCoreTableState';
import {
  useAppState,
  useAuthStateShared,
  useBusinessEntityState,
  useHttpApi,
  useWeb3
} from 'state';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';
import { format, addDays } from 'date-fns';
import DateAdapter from '@mui/lab/AdapterDateFns';
import FocusError from 'components/FocusError';
import DownloadIcon from '@mui/icons-material/Download';
import UpdateTokenTerms from 'components/CreateToken/UpdateTokenTerms';
import AccordionLayout from 'helpers/AccordionLayout';
import { useWeb3State } from 'state/useWeb3State';
import AddressFieldTools from 'components/AddressFieldTools';

const BTLEditToken = () => {
  const { tokenById, currentToken, fetchCurrentToken, setCurrentTokenById } =
    useBusinessEntityState();
  const [status, setStatus] = useState(false);
  const { editToken, getTokenDetailsById } = useHttpApi();

  useEffect(() => {
    if (currentToken.token_status_id.token_status !== 'NEW') {
      setStatus(true);
    }
  }, [tokenById, currentToken]);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const issueDatehandleChange = (newDate) => {
    try {
      formik.setFieldValue('token_start_date', format(newDate, 'yyyy-MM-dd'));
    } catch (err) {
      console.log(err);
    }
  };

  const { issueType, issueCategories } = useCoreTableState();

  const { setToken, fetchTokens } = useBusinessEntityState();

  const { throwErrorMessage } = useAppState();
  const { entity } = useAuthStateShared();
  const { enqueueSnackbar } = useSnackbar();
  const { getTotalSupply } = useWeb3State();
  const navigate = useNavigate();

  const CreateTokenSchema = Yup.object().shape({
    token_name: Yup.string()
      .required('Token Name is required')
      .max(50, 'Token Name cannot be more than 50 characters'),
    token_symbol: Yup.string()
      .required('Token Symbol is required')
      .max(11, 'Token Symbol cannot be more than 11 characters'),
    issuer_id: Yup.string().required('Issuer is required'),
    // token_currency: Yup.string().required('Currency is required'),
    total_supply: Yup.number()
      .required('Token Supply is required')
      .min(1, 'Token Supply must be positive number')
      .max(100000000000, 'Token Supply must be less'),
    token_value: Yup.number()
      .required('Token Value is required')
      .min(1, 'Token Value must be positive number')
      .max(1000000000, 'Token Value must be less'),
    max_issue_size: Yup.number()
      .positive('Issue Size must be positive number')
      .required('Issue Size is required'),
    issue_type_id: Yup.string().required('Issue Type is required'),
    issue_category_id: Yup.string().required('Issue Category is required'),
    issue_sub_category_id: Yup.string().required('Issue Sub Category is required'),
    fund_manager: Yup.string().required('Fund Manager is required'),
    // Primary Economic Terms

    minimum_subscription: Yup.number()
      .required('Minimum Subscription is required')
      .min(1, 'Minimum Subscription cannot be less than 1'),

    token_start_date: Yup.date()
      .required('Issue Start Date is required')
      .min(today, 'Issue Start Date cannont be past date'),

    token_nav: Yup.number().required('Token NAV is required'),
    contract_doc_hash1: Yup.mixed().test(
      'type',
      'Only .pdf .doc .docx formats are accepted',
      (value) => {
        let allGood = false;

        if (value) {
          allGood = value && value.substring(value.lastIndexOf('.')) === '.pdf';
        } else {
          allGood = true;
        }
        return allGood;
      }
    ),
    use_of_proceeds: Yup.string().required('Use of Proceeds is required')
    // deployment_address: Yup.string()
  });
  const contractDoc = useRef(null);
  // Formik

  const formik = useFormik({
    initialValues: { ...tokenById },
    validate: (values, props) => {
      // delete values.lockin_till;
      const errors = {};

      if (values.contract_doc_hash1) {
        if (contractDoc && contractDoc.current.files[0].size > 20971520) {
          errors.contract_doc_hash1 = 'File size greater than 20MB';
        }
      }
      return errors;
    },
    validationSchema: CreateTokenSchema,
    onSubmit: async (data, { resetForm }) => {
      console.log('EditToken', data);
      try {
        const contractDocFile = contractDoc.current.files[0];
        if (contractDocFile) {
          if (contractDocFile.size > 20971520) {
            throw new Error('Upload Contract File less than 20MB', {
              variant: 'error'
            });
          }
          data.contract_doc_hash = contractDocFile;
          formik.setFieldValue('contract_doc_hash1', '');
        } else {
          delete data.contract_doc_hash;
        }

        console.log(JSON.stringify(data, null, ' '));
        const _editToken = await editToken(tokenById.token_id, data);
        if (_editToken.token_id) {
          setCurrentTokenById(_editToken);
          const res = await getTokenDetailsById(_editToken.token_id);
          console.log(res);
          fetchCurrentToken(res);
          if (res.token_id) {
            setToken(res);
          }
          enqueueSnackbar('Token Updated Successfully', {
            variant: 'success'
          });
          resetForm();
          navigate('/home/token-dashboard');
          fetchTokens();
        }
      } catch (e) {
        throwErrorMessage(e);
      }
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleIssueSize = () => {
    const _issueSize = formik.values.total_supply * formik.values.token_value;
    formik.setFieldValue('max_issue_size', _issueSize);
    return _issueSize;
  };

  const handleTokenValueChange = (e) => {
    // formik.setFieldValue('token_redemption_price', values.token_value);
    formik.setFieldValue('token_nav', formik.values.token_value);
    return values.token_value;
  };

  useEffect(() => {
    console.log('token by id', tokenById);
    formik.resetForm({ values: tokenById });
  }, [tokenById]);

  return (
    <>
      <Page title="Edit Token">
        <Container sx={{ mt: 3 }}>
          <Breadcrumbs aria-label="breadcrumb" pageHead="" />
          <Card sx={{ mt: 2 }}>
            <CardContent sx={{ m: 4 }}>
              <FormikProvider value={formik}>
                <Form autoComplete="off" onSubmit={handleSubmit}>
                  <AccordionLayout
                    defaultExpanded
                    title="Token Header"
                    content={
                      <Grid container spacing={3} sx={{ width: '100%' }}>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pr: 1 }}>
                          <FormLabel>Issuer Name</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              inputProps={{
                                readOnly: status
                              }}
                              {...getFieldProps('issuer_id')}
                              error={Boolean(touched.issuer_id && errors.issuer_id)}
                              helperText={touched.issuer_id && errors.issuer_id}
                            >
                              {/* {entity.issuer_id && ( */}
                              <MenuItem
                                selected
                                className="Mui-selected"
                                key={entity?.issuer_id}
                                value={entity?.issuer_id}
                              >
                                {entity?.issuer_name}
                              </MenuItem>
                              {/* )} */}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issuer_id && errors.issuer_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} sx={{ pl: 0.5 }}>
                          <FormLabel>Issue Currency</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            readOnly
                            inputProps={{
                              readOnly: true,
                              maxLength: 50
                            }}
                            size="small"
                            autoComplete="off"
                            type="text"
                            value="USD"
                            // {...getFieldProps('token_currency')}
                            error={Boolean(touched.token_currency && errors.token_currency)}
                            helperText={touched.token_currency && errors.token_currency}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Name</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            inputProps={{
                              readOnly: status,
                              maxLength: 50
                            }}
                            size="small"
                            autoComplete="off"
                            type="text"
                            // placeholder="Token Name"
                            {...getFieldProps('token_name')}
                            error={Boolean(touched.token_name && errors.token_name)}
                            helperText={touched.token_name && errors.token_name}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Symbol</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            inputProps={{
                              readOnly: status,
                              maxLength: 11
                            }}
                            autoComplete="off"
                            type="text"
                            // placeholder="XDCYP"
                            {...getFieldProps('token_symbol')}
                            error={Boolean(touched.token_symbol && errors.token_symbol)}
                            helperText={touched.token_symbol && errors.token_symbol}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Max. Supply</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            inputProps={{
                              readOnly: status
                            }}
                            {...getFieldProps('total_supply')}
                            onWheel={(e) => {
                              e.target.blur();
                            }}
                            onBlur={() => {
                              handleIssueSize();
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                e.preventDefault(); // Prevents incrementing/decrementing with arrow keys
                              }
                            }}
                            error={Boolean(touched.total_supply && errors.total_supply)}
                            helperText={touched.total_supply && errors.total_supply}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Value (USD)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            inputProps={{
                              readOnly: status
                            }}
                            // placeholder="1000"
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="number"
                            {...getFieldProps('token_value')}
                            id="token_value"
                            onBlur={() => {
                              handleIssueSize();
                              handleTokenValueChange();
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                                e.preventDefault(); // Prevents incrementing/decrementing with arrow keys
                              }
                            }}
                            error={Boolean(touched.token_value && errors.token_value)}
                            helperText={touched.token_value && errors.token_value}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Max. Issue Size (USD)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            type="number"
                            inputProps={{
                              readOnly: true
                            }}
                            size="small"
                            // placeholder="10000000"
                            {...getFieldProps('max_issue_size')}
                            error={Boolean(touched.issue_size && errors.issue_size)}
                            helperText={touched.issue_size && errors.issue_size}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Type</FormLabel>

                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              // placeholder="Select Issue Type"
                              displayEmpty
                              inputProps={{
                                'aria-label': 'Without label',
                                readOnly: status
                              }}
                              {...getFieldProps('issue_type_id')}
                              id="issue_type_id"
                              onChange={(event) => {
                                const _issueType = issueType.filter(
                                  (pay) => event.target.value === pay.id
                                );
                                formik.setFieldValue('issue_type_id', event.target.value);
                              }}
                              error={Boolean(touched.issue_type_id && errors.issue_type_id)}
                              helperText={touched.issue_type_id && errors.issue_type_id}
                            >
                              {issueType.map((issueType) => {
                                return (
                                  <MenuItem key={issueType.id} value={issueType.id}>
                                    {issueType.issue_type_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issue_type_id && errors.issue_type_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Category</FormLabel>
                          <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                            <Select
                              readOnly
                              displayEmpty
                              inputProps={{
                                'aria-label': 'Without label'
                              }}
                              {...getFieldProps('issue_category_id')}
                              error={Boolean(touched.issue_category_id && errors.issue_category_id)}
                              helperText={touched.issue_category_id && errors.issue_category_id}
                            >
                              {issueCategories?.map((category) => {
                                return (
                                  <MenuItem key={category.id} value={category.id}>
                                    {category.issue_category_name}
                                  </MenuItem>
                                );
                              })}
                            </Select>

                            <FormHelperText sx={{ color: '#d32f2f' }}>
                              {touched.issue_category_id && errors.issue_category_id}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Fund Manager</FormLabel>
                          <TextField
                            inputProps={{
                              readOnly: true
                            }}
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            type="text"
                            id="fund_manager"
                            {...getFieldProps('fund_manager')}
                            error={Boolean(touched.fund_manager && errors.fund_manager)}
                            helperText={touched.fund_manager && errors.fund_manager}
                          />
                        </Grid>
                      </Grid>
                    }
                  />
                  <AccordionLayout
                    defaultExpanded
                    title="Token Economics Terms"
                    content={
                      <Grid container spacing={3} sx={{ width: '100%' }}>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token Term Sheet</FormLabel>
                          <Grid
                            container
                            sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                          >
                            <Grid item lg={11} md={10} sm={12} xs={12}>
                              <TextField
                                sx={{ mt: 1.5 }}
                                fullWidth
                                size="small"
                                autoComplete="off"
                                type="file"
                                inputProps={{ disabled: status, accept: '.pdf' }}
                                inputRef={contractDoc}
                                {...getFieldProps('contract_doc_hash1')}
                                error={Boolean(
                                  touched.contract_doc_hash1 && errors.contract_doc_hash1
                                )}
                                helperText={touched.contract_doc_hash1 && errors.contract_doc_hash1}
                              />

                              <Grid container sx={{ width: '100%', mt: 2 }}>
                                <Grid item lg={4} md={4} sm={4}>
                                  <Typography
                                    sx={{
                                      fontSize: '0.688rem',
                                      textAlign: 'left',
                                      color: '#161c2d'
                                    }}
                                  >
                                    Maximum 20MB file size
                                  </Typography>
                                </Grid>
                                <Grid item lg={8} md={8} sm={8}>
                                  <Typography
                                    sx={{
                                      fontSize: '0.688rem',
                                      textAlign: 'right',
                                      color: '#8e8ea7'
                                    }}
                                  >
                                    Accepted File Type .pdf only
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item lg={1} md={2} sm={1} xs={1}>
                              <IconButton
                                sx={{ mt: -3 }}
                                aria-label="subs detail"
                                onClick={() => window.open(tokenById.contract_doc_hash, '_blank')}
                              >
                                <DownloadIcon
                                  sx={{ fontSize: '2.188rem', p: 1, backgroundColor: '#F5F8FA' }}
                                />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Issue Start Date</FormLabel>
                          <LocalizationProvider dateAdapter={DateAdapter}>
                            <DesktopDatePicker
                              fullWidth
                              size="small"
                              inputFormat="MM-dd-yyyy"
                              sx={{ mt: 1.5 }}
                              {...getFieldProps('token_start_date')}
                              value={formik.values.token_start_date}
                              onChange={issueDatehandleChange}
                              renderInput={(params) => (
                                <TextField
                                  sx={{ mt: 1.5 }}
                                  fullWidth
                                  size="small"
                                  {...params}
                                  {...getFieldProps('token_start_date')}
                                  error={Boolean(
                                    touched.token_start_date && errors.token_start_date
                                  )}
                                  helperText={touched.token_start_date && errors.token_start_date}
                                />
                              )}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Minimum Susbcription Qty</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            type="number"
                            inputProps={{
                              readOnly: status
                            }}
                            autoComplete="off"
                            // placeholder="1"
                            {...getFieldProps('minimum_subscription')}
                            error={Boolean(
                              touched.minimum_subscription && errors.minimum_subscription
                            )}
                            helperText={touched.minimum_subscription && errors.minimum_subscription}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Blockchain</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="1000"
                            fullWidth
                            inputProps={{
                              readOnly: true
                            }}
                            size="small"
                            autoComplete="off"
                            type="text"
                            {...getFieldProps('blockchain')}
                            error={Boolean(touched.blockchain && errors.blockchain)}
                            helperText={touched.blockchain && errors.blockchain}
                          />
                        </Grid>
                        {currentToken.deployment_address &&
                          currentToken.deployment_address !== 'null' && (
                            <Grid item lg={6} md={6} sm={12} xs={12}>
                              <FormLabel>Contract Address</FormLabel>
                              <Grid container>
                                <Grid item lg={12} md={12} sm={12}>
                                  <TextField
                                    sx={{ mt: 1.5 }}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    type="text"
                                    value={currentToken.deployment_address}
                                    // {...getFieldProps('contract_address')}
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <AddressFieldTools
                                            address={currentToken.deployment_address}
                                            showAddress={false}
                                            symbol={currentToken.token_symbol}
                                            decimals={0}
                                            showInBlockExplorer
                                            showCopyButton
                                            showAddToWallet
                                          />
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          )}
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Token NAV (USD)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="1000"
                            fullWidth
                            inputProps={{
                              readOnly: status
                            }}
                            size="small"
                            autoComplete="off"
                            type="number"
                            {...getFieldProps('token_nav')}
                            error={Boolean(touched.token_nav && errors.token_nav)}
                            helperText={touched.token_nav && errors.token_nav}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Platform Fees (%) </FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            // placeholder="1000"
                            fullWidth
                            inputProps={{
                              readOnly: status
                            }}
                            size="small"
                            autoComplete="off"
                            type="number"
                            {...getFieldProps('platform_fees')}
                            error={Boolean(touched.platform_fees && errors.platform_fees)}
                            helperText={touched.platform_fees && errors.platform_fees}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Settlement Cycle (T + Days)</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            inputProps={{ 'aria-label': 'Without label', readOnly: status }}
                            // placeholder="45"
                            type="number"
                            {...getFieldProps('settlement_cycle')}
                            error={Boolean(touched.settlement_cycle && errors.settlement_cycle)}
                            helperText={touched.settlement_cycle && errors.settlement_cycle}
                          />
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Mode of Payment</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            inputProps={{ 'aria-label': 'Without label', readOnly: true }}
                            // placeholder="45"
                            type="text"
                            {...getFieldProps('mode_of_payment')}
                            error={Boolean(touched.mode_of_payment && errors.mode_of_payment)}
                            helperText={touched.mode_of_payment && errors.mode_of_payment}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} sm={12} xs={12}>
                          <FormLabel>Use of Token Sale Proceeds</FormLabel>
                          <TextField
                            sx={{ mt: 1.5 }}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            // placeholder="45"
                            type="text"
                            {...getFieldProps('use_of_proceeds')}
                            error={Boolean(
                              touched.application_processing_time &&
                                errors.application_processing_time
                            )}
                            helperText={
                              touched.application_processing_time &&
                              errors.application_processing_time
                            }
                          />
                        </Grid>
                        {/* TODO: Get file */}
                      </Grid>
                    }
                  />

                  <Grid display="flex" justifyContent="flex-end">
                    {currentToken.token_status_id.token_status === 'NEW' && (
                      <>
                        <Button
                          sx={{
                            mt: 2,
                            borderColor: '#24ABDF',
                            px: 3,
                            mr: 1,
                            width: '7.75rem',
                            height: '2.5rem'
                          }}
                          variant="contained"
                          color="secondary"
                          onClick={() => {
                            navigate('/home/token-dashboard');
                          }}
                        >
                          Cancel
                        </Button>
                      </>
                    )}
                    {!status && (
                      <LoadingButton
                        sx={{
                          width: '7.5rem',
                          height: '2.5rem',
                          mt: 2
                        }}
                        disabled={status}
                        variant="gradient"
                        loadingPosition="start"
                        onClick={handleSubmit}
                      >
                        Save
                      </LoadingButton>
                    )}
                  </Grid>
                  <FocusError />
                </Form>
              </FormikProvider>
              {currentToken.token_status_id.token_status !== 'NEW' && (
                <>
                  <Box sx={{ mt: 2 }}>
                    <AccordionLayout title="Token Updates" content={<UpdateTokenTerms />} />
                  </Box>
                </>
              )}
              {currentToken.token_status_id.token_status !== 'NEW' && (
                <Grid display="flex" justifyContent="flex-end">
                  <Button
                    sx={{
                      mt: 2,
                      borderColor: '#24ABDF',
                      px: 3,
                      mr: 1,
                      width: '7.75rem',
                      height: '2.5rem'
                    }}
                    variant="outlined"
                    color="error"
                    onClick={() => {
                      navigate('/home/token-dashboard');
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              )}
            </CardContent>
          </Card>
        </Container>
      </Page>
    </>
  );
};
export default BTLEditToken;
