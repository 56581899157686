import { Navigate, useNavigate, useRoutes } from 'react-router-dom';

import MainLayout from './layouts/main';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';

import Login from './pages/Login';
import Signup from './pages/Signup';
import MainView from './pages/Main';
import AnotherView from './pages/AnotherView';
import NotFound from './pages/Page404';

import AuthRequired from 'components/AuthRequired';
import AllComponents from 'pages/AllComponents';
import SubscriptionLayout from 'layouts/SubscriptionLayout';
import SubscriptionHome from 'pages/subscriptions/SubscriptionHome';
import IssuerLayout from 'layouts/IssuerLayout';
import IssuerHome from 'pages/issuer/IssuerHome';
import SubscriptionForm from 'pages/subscriptions/SubscriptionForm';
import TokenDashboard from 'pages/issuer/TokenDashboard';
import RequestForgotPassword from 'pages/RequestForgotPassword';
import ForgotPassword from 'pages/ForgotPassword';
// import CreateTokenReview from 'pages/issuer/CreateTokenReview';
import CreateAllotment from 'pages/issuer/CreateAllotment';
import MaintainWhitelist from 'pages/issuer/MaintainWhitelist';
import ViewAllotment from 'pages/issuer/ViewAllotment';
import EditInvestor from 'pages/masterData/investor/EditInvestor';
import AllInvestor from 'pages/masterData/investor/AllInvestor';
import AllCustodian from 'pages/masterData/custodian/AllCustodian';
import EditIssuer from 'pages/masterData/issuer/EditIssuer';
import EditCustodian from 'pages/masterData/custodian/EditCustodian';
import AddCustodian from 'pages/masterData/custodian/AddCustodian';
import EditKYC from 'pages/masterData/KYCProvider/EditKYC';
import AllKycProvider from 'pages/masterData/KYCProvider/AllKycProvider';
import EditCashTxn from 'pages/masterData/cashtxn/EditCashTxn';
import CryptoAssetTxn from 'pages/masterData/cryptoTxn/CryptoAssetTxn';
import EditCryptoTxn from 'pages/masterData/cryptoTxn/EditCryptoTxn';
import AddCryptoTxn from 'pages/masterData/cryptoTxn/AddCryptoTxn';
import AddKYCProvider from 'pages/masterData/KYCProvider/AddKycProvider';
import AddCashTxn from 'pages/masterData/cashtxn/AddCashTxn';
import AllCashTxn from 'pages/masterData/cashtxn/AllCashTxn';
import AllIssuer from 'pages/masterData/issuer/AllIssuer';
import AllBankAccount from 'pages/masterData/bankaccount/AllBankAccount';
import EditBankAccount from 'pages/masterData/bankaccount/EditBankAccount';
import EditUser from 'pages/masterData/user/EditUser';
import AddIssuer from 'pages/masterData/issuer/AddIssuer';
import AddUser from 'pages/masterData/user/AddUser';
import AllUser from 'pages/masterData/user/AllUser';
import EditXinfin from 'pages/masterData/xinfinAcc/EditXinfin';
import AddXinfin from 'pages/masterData/xinfinAcc/AddXinfi';
import AllXinfin from 'pages/masterData/xinfinAcc/AllXinfin';
import KYCDashboard from 'pages/issuer/KYC/KYCDashboard';
import KYCuser from 'pages/issuer/KYC/KYCuser';
import InvestorDashboard from 'pages/subscriptions/InvestorDashboard';
import MasterdataDashboard from 'pages/masterData/MasterdataDashboard';
import AddInvestor from 'pages/masterData/investor/AddInvestor';
import AddBankAccount from 'pages/masterData/bankaccount/AddBankAccount';
import CreateToken from 'pages/issuer/CreateToken';
import ManageCriteria from 'pages/issuer/ManageCriteria';
import AddOriginator from 'pages/masterData/originator/AddOriginator';
import AllOriginator from 'pages/masterData/originator/AllOriginator';
import EditOriginator from 'pages/masterData/originator/EditOriginator';
// import BTLEditToken from 'pages/issuer/BTLEditToken';
import EditToken from 'pages/issuer/EditToken';
import EscrowSettlement from 'pages/issuer/EscrowSettlement';
import OwnerDashboard from 'pages/owner/OwnerDashboard';
import ChangePassword from 'pages/ChangePassword';
import Home from 'pages/Home';
import ViewToken from 'pages/subscriptions/ViewToken';
import IssuerWhitelist from 'pages/masterData/IssuerWhitelist';
import AddCreditInsurer from 'pages/masterData/creditInsurer/AddCreditInsurer';
import AllCreditInsurer from 'pages/masterData/creditInsurer/AllCreditInsurer';
import EditCreditInsurer from 'pages/masterData/creditInsurer/EditCreditInsurer';
import KYCSubscriber from 'pages/kycprovider/KYCSubscriber';
import CreditInsurerDashboard from 'pages/masterData/creditInsurer/CreditInsurerDashboard';
import AddOrganization from 'pages/issuer/AddOrganization';
import { resourcePersistence } from 'persistence';
import { Payment } from '@mui/icons-material';
import PaymentTxn from 'pages/issuer/PaymentTxn';
import AllMappedIssuers from 'pages/masterData/creditInsurer/AllMappedIssuers';
import AllCollateralTxn from 'pages/masterData/collateral/AllCollateralTxn';
import IssuerInsurerMap from 'pages/issuer/IssuerInsurerMap';
import AllNotifications from 'pages/AllNotifications';
import DocMgtDashboard from 'pages/documentManagement/DocMgtDashboard';
import DocMgtUser from 'pages/documentManagement/DocMgtUser';
import AddIssueType from 'pages/documentManagement/AddIssueType';
import AddDocType from 'pages/documentManagement/AddDocType';
import AddDocSubType from 'pages/documentManagement/AddDocSubType';
import AssociateDocType from 'pages/documentManagement/AssociateDocType';
import AddLiquidityPool from 'pages/masterData/liquidityPool/AddLiquidityPool';
import AllLiquidityPool from 'pages/masterData/liquidityPool/AllLiquidityPool';
import EditLiquidityPool from 'pages/masterData/liquidityPool/EditLiquidityPool';
import PoolHome from 'pages/subscriptions/PoolHome';
import PoolDetails from 'pages/subscriptions/PoolDetails';
import PoolPerfomanaceDashboard from 'pages/masterData/liquidityPool/PoolPerfomanaceDashboard';
import TokenNotifications from 'pages/TokenNotifications';
import ViewProxyTokenDetails from 'components/subscritiption/ViewProxyTokenDetails';
import BondInformation from 'components/BondInformation';
import AddFundManager from 'pages/masterData/fundManager/AddFundManager';
import AllFundManagers from 'pages/masterData/fundManager/AllFundManagers';
import EditFundManager from 'pages/masterData/fundManager/EditFundManager';
import FundManagerHome from 'pages/fundManager/FundManagerHome';
import FundManagerLayout from 'layouts/FundManagerLayout';
import BTLTokenDashboard from 'pages/issuer/BTLTokenDashboard';
import CustomerDetails from 'components/issuerDashboard/CustomerDetails';
import BTLViewAllotment from 'pages/issuer/BTLViewAllotment';
import BTLViewRedemption from 'pages/issuer/BTLViewRedemption';
import BTLSubscriptions from 'pages/issuer/BTLSubscriptions';
import BTLEditToken from 'pages/issuer/BTLEditToken';
import BTLFeesCharges from 'pages/issuer/BTLFeesCharges';
import BTLReports from 'pages/issuer/BTLReports';

const Router = () => {
  const navigate = useNavigate();
  // For Development, update the the version manually in Code not in env file or AWS environment variables
  // Update Only {patch-version} and only on Deployment
  const version = process.env.REACT_APP_APP_VERSION || '1.0.5';
  if (resourcePersistence.get('VERSION') && resourcePersistence.get('VERSION') !== version) {
    localStorage.clear();
    resourcePersistence.set('VERSION', version);
    navigate('/');
  } else if (!resourcePersistence.get('VERSION')) {
    resourcePersistence.set('VERSION', version);
  }

  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'sign-up', element: <Signup /> },
        { path: 'request-forgot-password', element: <RequestForgotPassword /> },
        { path: 'forgot-password/:token', element: <ForgotPassword /> },
        { path: 'all', element: <AllComponents /> },
        { path: '404', element: <NotFound /> },
        { path: 'multisig', element: <Navigate to="/home" /> },
        { path: 'multisig/subscription', element: <Navigate to="/subscription" /> },
        { path: '/', element: <Navigate to="/home" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/subscription',
      element: (
        <AuthRequired>
          <SubscriptionLayout />
        </AuthRequired>
      ),
      children: [
        { path: '/', element: <SubscriptionHome /> },
        { path: '/form', element: <SubscriptionForm /> },
        { path: '/form/:tokenAddress', element: <SubscriptionForm /> },
        { path: '/investor-dashboard', element: <InvestorDashboard /> },
        { path: '/view-token', element: <ViewToken /> },
        { path: '/notifications', element: <AllNotifications /> },
        { path: '/liquidity-pools', element: <PoolHome /> },
        { path: '/liquidity-pool-detail', element: <PoolDetails /> },
        { path: '/proxy-token-details', element: <ViewProxyTokenDetails /> },
        { path: '/investment-information', element: <BondInformation /> },
        { path: '/change-password', element: <ChangePassword /> }
      ]
    },
    {
      path: '/home',
      element: (
        <AuthRequired>
          <IssuerLayout />
        </AuthRequired>
      ),
      children: [
        { path: '/', element: <Home /> },
        { path: '/token-dashboard', element: <BTLTokenDashboard /> },
        {
          path: '/token-dashboard',
          children: [
            { path: '/manage-criteria', element: <ManageCriteria /> },
            { path: '/maintain-whitelist', element: <MaintainWhitelist /> },
            // { path: '/create-allotment', element: <CreateAllotment /> },
            { path: '/subscriptions', element: <BTLSubscriptions /> },
            { path: '/reports', element: <BTLReports /> },
            { path: '/view-allotment', element: <BTLViewAllotment /> },
            { path: '/view-redemption', element: <BTLViewRedemption /> },
            { path: '/fees-charges', element: <BTLFeesCharges /> },
            { path: '/customer-details', element: <CustomerDetails /> },

            // { path: '/review', element: <CreateTokenReview /> },
            { path: '/edit-token', element: <BTLEditToken /> },
            // { path: '/edit-token', element: <EditToken /> },
            { path: '/kyc-dashboard', element: <KYCDashboard /> },
            { path: '/kyc-user', element: <KYCuser /> },
            { path: '/escrow-settlement', element: <EscrowSettlement /> },
            { path: '/doc-manage-dashboard', element: <DocMgtDashboard /> },
            { path: '/doc-manage-user', element: <DocMgtUser /> },
            { path: '/token-notifications', element: <TokenNotifications /> },
            { path: '/payment-transactions', element: <PaymentTxn /> }
          ]
        },
        { path: '/create-token', element: <CreateToken /> },
        { path: '/admin-dashboard', element: <OwnerDashboard /> },
        { path: '/add-organization', element: <AddOrganization /> },
        { path: '/change-password', element: <ChangePassword /> },
        { path: '/issuer-insurer-map', element: <IssuerInsurerMap /> },
        { path: '/notifications', element: <AllNotifications /> },
        { path: '/add-issue-type', element: <AddIssueType /> },
        { path: '/add-doc-type', element: <AddDocType /> },
        { path: '/add-doc-sub-type', element: <AddDocSubType /> },
        { path: '/map-doc-types', element: <AssociateDocType /> },
        { path: '/performance-dashboard', element: <PoolPerfomanaceDashboard /> }
      ]
    },

    {
      path: '/fund-manager',
      element: (
        <AuthRequired>
          <FundManagerLayout />
        </AuthRequired>
      ),
      children: [
        {
          path: '/',
          element: <FundManagerHome />
        }
      ]
    },
    {
      path: '/kyc',
      element: (
        <AuthRequired>
          <IssuerLayout />
        </AuthRequired>
      ),
      children: [{ path: '/subscriber', element: <KYCSubscriber /> }]
    },
    {
      path: '/credit-insurer',
      element: (
        <AuthRequired>
          <IssuerLayout />
        </AuthRequired>
      ),
      children: [
        { path: '/all-mapped-issuers', element: <AllMappedIssuers /> },
        { path: '/dashboard', element: <CreditInsurerDashboard /> }
      ]
    },
    {
      path: '/masterdata',
      element: (
        <AuthRequired>
          <IssuerLayout />
        </AuthRequired>
      ),
      children: [
        { path: '/', element: <MasterdataDashboard /> },

        { path: '/investor', element: <AllInvestor /> },
        { path: '/investor/add-investor', element: <AddInvestor /> },
        { path: '/investor/edit-investor', element: <EditInvestor /> },

        { path: '/fund-manager', element: <AllFundManagers /> },
        { path: '/fund-manager/add-fund-manager', element: <AddFundManager /> },
        { path: 'fund-manager/edit-fund-manager', element: <EditFundManager /> },
        // { path: '/custodian', element: <AllCustodian /> },
        // { path: '/custodian/add-custodian', element: <AddCustodian /> },
        // { path: '/custodian/edit-custodian', element: <EditCustodian /> },

        { path: '/issuer', element: <AllIssuer /> },
        { path: '/issuer/edit-issuer', element: <EditIssuer /> },
        { path: '/issuer/add-issuer', element: <AddIssuer /> },
        { path: '/issuer/issuer-whitelist', element: <IssuerWhitelist /> },

        { path: '/kyc-provider', element: <AllKycProvider /> },
        { path: '/kyc-provider/add-kyc-provider', element: <AddKYCProvider /> },
        { path: '/kyc-provider/edit-kyc-provider', element: <EditKYC /> },

        { path: '/crypto-asset-txn', element: <CryptoAssetTxn /> },
        { path: '/crypto-asset-txn/edit-crypto-asset-txn', element: <EditCryptoTxn /> },
        { path: '/crypto-asset-txn/crypto-txn-payment', element: <AddCryptoTxn /> },

        { path: '/cash-txn', element: <AllCashTxn /> },
        { path: '/cash-txn/add-cash-txn', element: <AddCashTxn /> },
        { path: '/cash-txn/edit-cash-txn', element: <EditCashTxn /> },

        { path: '/collateral-txn', element: <AllCollateralTxn /> },

        { path: '/bank-account', element: <AllBankAccount /> },
        { path: '/bank-account/add-bank-account', element: <AddBankAccount /> },
        { path: '/bank-account/edit-bank-account', element: <EditBankAccount /> },

        { path: '/user', element: <AllUser /> },
        { path: '/user/edit-user', element: <EditUser /> },
        { path: '/user/add-user', element: <AddUser /> },

        { path: '/xinfin', element: <AllXinfin /> },
        { path: '/xinfin/edit-xinfin', element: <EditXinfin /> },
        { path: '/xinfin/add-xinfin', element: <AddXinfin /> },

        { path: '/originator', element: <AllOriginator /> },
        { path: '/originator/edit-originator', element: <EditOriginator /> },
        { path: '/originator/add-originator', element: <AddOriginator /> },

        { path: '/credit-insurer', element: <AllCreditInsurer /> },
        { path: '/credit-insurer/add-credit-insurer', element: <AddCreditInsurer /> },
        { path: '/credit-insurer/edit-credit-insurer', element: <EditCreditInsurer /> },

        // Issuer Insurer Mapping For Issuer
        { path: '/issuer-insurer-map', element: <IssuerInsurerMap /> },

        { path: '/liquidity-pool', element: <AllLiquidityPool /> },
        { path: '/liquidity-pool/add-liquidity-pool', element: <AddLiquidityPool /> },
        { path: '/liquidity-pool/edit-liquidity-pool', element: <EditLiquidityPool /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
};

export default Router;
