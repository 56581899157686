import * as Yup from 'yup';

const today = new Date();
today.setHours(0, 0, 0, 0);
const tmr = new Date() + 1;

const CreateTokenSchema = Yup.object().shape({
  token_name: Yup.string()
    .required('Token Name is required')
    .max(50, 'Token Name cannot be more than 50 characters'),
  token_symbol: Yup.string()
    .required('Token Symbol is required')
    .max(11, 'Token Symbol cannot be more than 11 characters'),
  issuer_id: Yup.string().required('Issuer is required'),
  total_supply: Yup.number()
    .required('Token Supply is required')
    .min(1, 'Token Supply must be positive number')
    .max(100000000000, 'Token Supply must be less'),
  token_value: Yup.number()
    .required('Token Value is required')
    .min(1, 'Token Value must be positive number')
    .max(1000000000, 'Token Value must be less than 1B'),
  max_issue_size: Yup.number()
    .positive('Max issue Size must be positive number')
    .max(10000000000, 'Issue size must be less than 10B')
    .required('Max issue Size is required'),
  issue_type_id: Yup.string().required('Issue Type is required'),
  fund_manager: Yup.string()
  .required('Fund Manger is required')
  .max(250, 'Fund Manager cannot be more than 250 characters'),  // Primary Economic Terms

  minimum_subscription: Yup.number()
    .required('Minimum Subscription is required')
    .min(1, 'Minimum Subscription cannot be less than 1'),


  token_start_date: Yup.date()
    .required('Issue Start Date is required')
    .min(today, 'Issue Start date cannont be past date'),

  token_nav: Yup.number().required('Token NAV is required'),

  platform_fees: Yup.number()
  .required('Platform Fees is required')
  .min(1, 'Platform Fees must be positive number'),

  mode_of_payment: Yup.string().required('Mode of payment is required'),

  settlement_cycle: Yup.number()
  .required('Settlement cycle is required')
  .min(1, 'Settlement cycle must be positive number'),

  use_of_proceeds: Yup.string().required('Use of sale proceeds is required'),

  blockchain: Yup.string().required('Blockchain name is required'),

});

export default CreateTokenSchema;
