import { Container, Box } from '@mui/material';
import Breadcrumbs from 'components/Breadcrumbs';
import { useBusinessEntityState, useAuthStateShared } from 'state';

import ReadOnlyNotifier from 'components/ReadOnlyNotifier';
import { onlyIssuer } from 'helpers/rbac';
import TokenTable from 'components/issuerDashboard/TokenTable';
import BTLSubscriptionTable from 'components/issuerDashboard/BTLSubscriptionTable';

const BTLSubscriptions = () => {
  const { currentToken: token } = useBusinessEntityState();
  const { role } = useAuthStateShared();

  return (
    <Container>
      <Breadcrumbs aria-label="breadcrumb" pageHead="" />

      {/* Token Table */}
      <TokenTable currToken={token} />
      {!onlyIssuer(role) && <ReadOnlyNotifier userRole={role} />}
      {/* Subscription Table */}
      <Box>
        <BTLSubscriptionTable />
      </Box>
    </Container>
  );
};

export default BTLSubscriptions;
