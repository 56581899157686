import { styled, Typography, Box, Grid, Button } from '@mui/material';
import { useEffect } from 'react';
import Page from '../components/Page';
import { LoginForm } from '../components/authentication/login';
import loginImage from '../assets/images/loginimage.png';
import Logo from 'components/Logo';
import { useNavigate } from 'react-router-dom';
import { userTokenPersistence } from 'persistence';
import XinfinLogin from 'components/XinfinLogin';
import { useCoreTableState, useSubscriptionState } from 'state';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    background: '#fff',
    height: '100vh'
  }
}));

const Login = () => {
  const navigate = useNavigate();
  const userToken = userTokenPersistence.get();

  useEffect(() => {
    if (userToken) {
      navigate('/home');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userToken]);

  // const { orgLogo } = useSubscriptionState();
  const { fetchOrganizations, orgnizations } = useCoreTableState();

  useEffect(() => {
    fetchOrganizations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RootStyle title="Login Taylored Digital Assets">
      <Grid container>
        <Grid item lg={6}>
          <Box
            component="img"
            sx={{ height: '90vh', marginTop: '10vh' }}
            src={loginImage}
            alt="login "
          />
          <Typography
            variant="h4"
            sx={{
              marginTop: '-10vh',
              marginLeft: '10vh',
              color: '#fff'
            }}
          >
            Taylored Digital Assets
          </Typography>
        </Grid>
        <Grid alignSelf="center" sx={{ mx: 'auto' }} item lg={4}>
          {/* <Box
            sx={{
              mx: 'auto',
              p: 1,
              marginBottom: '50px',
              borderRadius: 1,
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column'
            }}
          > */}

          {/* </Box> */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              mb: '2rem'
            }}
          >
            {orgnizations[0] && (
              <Box
                component="img"
                src={orgnizations[0].logo}
                sx={{ height: '6rem', width: 'auto' }}
              />
            )}
          </Box>

          <LoginForm />
          <Box
            sx={{
              mx: 'auto',
              p: 1,
              textAlign: 'center',
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <Button
              onClick={() => {
                navigate('/request-forgot-password');
              }}
            >
              Forgot Password?
            </Button>

            <Button
              onClick={() => {
                navigate('/sign-up');
              }}
            >
              Sign up as an Investor
            </Button>
          </Box>
          <XinfinLogin />
        </Grid>
      </Grid>
    </RootStyle>
  );
};

export default Login;
