import {
  Stepper,
  Box,
  Step,
  StepLabel,
  styled,
  StepConnector,
  stepConnectorClasses,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)'
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1D7114'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#1D7114'
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1
  }
}));
const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#1D7114'
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#1D7114',
    zIndex: 1,
    fontSize: 18
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor'
  }
}));
QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool
};

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckCircleRoundedIcon
          sx={{
            color: '#2BA41E',
            borderRadius: '50%',
            border: '1px solid #1D7114'
          }}
        />
      ) : (
        <CheckCircleRoundedIcon
          sx={{
            color: '#8D8D8D',

            borderRadius: '50%',

            border: '1px solid #8D8D8D'
          }}
        />
      )}
    </QontoStepIconRoot>
  );
}
const TokenTimeline = (props) => {
  // console.log('Timeline status', props);
  const steps = ['New', 'Published', 'Issued', 'All Redeemed', ' All Burnt'];

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper
        activeStep={props.statusNumber}
        connector={<QontoConnector />}
        orientation="vertical"
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>
              <Typography
                variant="body2"
                sx={{
                  fontSize: '0.75rem'
                }}
                color="textSecondary"
              >
                {label}
              </Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default TokenTimeline;
