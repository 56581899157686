import React, { useEffect, useState } from 'react';
import EnhancedTable from 'components/tables/EnhancedTable';
import { Box, IconButton, Button, Typography } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ethToXdcAddress } from 'helpers/web3';
import { HeaderName } from 'helpers/tableHelper';
import {
  useAppState,
  useAuthStateShared,
  useSubscriptionState,
  useWeb3,
  useBusinessEntityState,
  useHttpApi
} from 'state';
import rows from '../../mocks/Investor.json';
import { useSnackbar } from 'notistack';
import LoadingActionButton from 'helpers/LoadingActionButton';
import NAIcon from 'components/NAIcon';
import RedeemTokenModal from './RedeemTokenModal';
import AddressFieldTools from 'components/AddressFieldTools';

const ActionButtons = (row) => {
  console.log('🚀 ~ file: InvestorViewAllotment.js ~ line 13 ~ ActionButtons ~ row', row);
  const { enqueueSnackbar } = useSnackbar();
  const { account, tvtRedeem, poolRedeemTvt, web3, giveRedeemAllowance } = useWeb3();
  const { throwErrorMessage } = useAppState();
  const { role } = useAuthStateShared();
  const redeemValue = row.row.is_redeem_requested;
  // const [buttonDisable, setButtonDisable] = useState(redeemValue);
  const { investorRedemptionEscrow } = useBusinessEntityState();

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = async () => {
    setModalOpen(false);
  };

  // useEffect(() => {
  //   setButtonDisable(row.row.is_redeem_requested);
  // }, [row]);
  const { updateAllotmentRedeemRequestStatus } = useHttpApi();
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          m: 0,
          p: 0,
          alignItems: 'center'
        }}
      >
        <LoadingActionButton
          sx={{ mr: 1 }}
          variant="contained"
          color="primary"
          loadingPosition="start"
          disabled={!row.row.can_redeem}
          onClick={() => {
            if (row.row.token_subscription_id.token_id.is_paused) {
              enqueueSnackbar(
                'Token is paused therefore redemption is restricted. Please contact system administrator',
                { variant: 'error' }
              );
              return;
            }
            handleOpenModal();
          }}
        >
          Redeem
        </LoadingActionButton>
      </Box>
      <RedeemTokenModal
        open={isModalOpen}
        handleClose={handleCloseModal}
        value={row.row.token_nav}
        platformFees={row.row.token_subscription_id.token_id.platform_fees}
        maxTokens={row.row.alloted_qty - row.row.pending_redeemed_qty}
        allotmentId={row.row.id}
        issuerAddress={
          row.row.token_subscription_id.token_id.issuer_id.primary_issuer_xinfin_address
        }
        deploymentAddress={row.row.token_subscription_id.token_id.deployment_address}
        redeemRequestedQty={row.row.redeem_requested_qty}
        allRedeemRequestedQty={row.row.all_redeem_requested_qty}
        refreshTable={row.refreshTable}
      />
    </>
  );
};

export default function InvestorViewAllotment({ poolAddr }) {
  const [data, setData] = useState(rows);

  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [owner, setOwner] = useState(false);
  const { role } = useAuthStateShared();
  const { account, active, matureBalanceOf } = useWeb3();
  const [loading, setLoading] = useState(false);
  const { fetchAllotmentsByXinfinAddress, allotments } = useSubscriptionState();
  console.log('allotments', allotments);
  const fetchAccount = poolAddr || account;

  useEffect(() => {
    if (role === 'OWNER') {
      setOwner(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _fetchData = async () => {
    console.log('fetch data - - - ');
    await fetchAllotmentsByXinfinAddress(fetchAccount);
  };

  useEffect(() => {
    _fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolAddr, account]);

  const headCells = [
    {
      accessor: 'token_subscription_id.token_id.token_symbol',
      Header: 'Token Symbol',
      width: 70,
      show: true
    },
    {
      accessor: '',
      Header: 'Token Address',
      show: true,
      width: 200,
      Cell: ({ row: { original } }) => {
        return (
          <AddressFieldTools
            address={original?.token_subscription_id?.token_id?.deployment_address}
            decimals={0}
            showCopyButton
            showInBlockExplorer
          />
        );
      }
    },

    {
      accessor: 'alloted_qty',
      Header: 'Token Alloted',
      width: 50,
      show: true
    },

    {
      accessor: 'token_subscription_id.token_id.token_value',
      Header: 'Token Value',
      width: 60,
      show: true
    },
    {
      accessor: 'allotment_date',
      Header: 'Allotment Date',
      width: 90,
      show: true
    },
    {
      accessor: 'token_subscription_id.token_price',
      Header: 'Buy Price',
      width: 90,
      show: true
    },
    {
      accessor: 'token_nav',
      Header: 'Current NAV',
      width: 90,
      show: true
    },
    {
      accessor: 'action_button',
      Header: 'Actions',
      width: 130,
      show: true
    }
  ];

  useEffect(() => {
    const _prepareRows = async () => {
      console.log('allotments in useffect', allotments);
      const _rows = await Promise.all(
        allotments.map(async (row) => {
          console.log('row', row);
          console.log('requested status', row, row.is_redeem_requested);
          row.action_button = (
            <ActionButtons row={row} poolAddr={poolAddr} refreshTable={_fetchData} />
          );
          return row;
        })
      );
      console.log('_rows - -', _rows);
      setData(_rows);
    };
    _prepareRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allotments]);
  const updateMyData = (rowIndex, columnId, value) => {
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <EnhancedTable
        tableTitle="InvestorViewAllotment"
        columns={headCells}
        data={data}
        setData={setData}
        updateMyData={updateMyData}
        skipPageReset={skipPageReset}
        refreshFunction={_fetchData}
      />
    </>
  );
}
