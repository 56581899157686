import React, { useState } from 'react';
import { Button, Modal, Box, Typography, TextField, FormControl ,Card, Grid, FormLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import { useHttpApi } from 'state';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PaidIcon from '@mui/icons-material/Paid';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider, ErrorMessage } from 'formik';
import FocusError from 'components/FocusError';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';




// Modal component to show Plaid account information
const OfflineCashTransactionModal = ({ recordId, type }) => {
  const [accountInfo, setAccountInfo] = useState(null);
  const [accountInfoNotAvailable, setAccountInfoNotAvailable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState();
  const { createOfflineCashTransaction, getSubscriptionDetailsById, getRedemptionDetailsById } = useHttpApi();
  const [submitting, setSubmitting] = useState(false);
  const [subscriptonDetails, setSubscriptionDetails] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [viewOnly, setViewOnly ] = useState(false);



  const [initialValues, setInitialValues] = useState({
    source_bank_account: '',
    destination_bank_account: '',
    transaction_ref_number:'',
    token_quantity:'',
    token_nav:'',
    trade_consideration: '',
    fees: '',
    total_payment: '',
    early_bird_investor:''
  });
  
  let CreateCashTransactionSchema;
  
  if(type === "SUBSCRIPTION"){
    CreateCashTransactionSchema = Yup.object().shape({
        source_bank_account: Yup.string().trim().required('Source bank account is required'),
        destination_bank_account: Yup.string().trim().required('Destination bank account is required'),
        transaction_ref_number:Yup.string().trim().required('Transaction Reference number is required'),
        token_quantity:Yup.number().required('Token quantity is required'),
        token_nav:Yup.number().required('Token NAV is required'),
        trade_consideration: Yup.string().required('Trade consideration'),
        fees: Yup.string().required('Fees is required'),
        total_payment: Yup.string().required('Total payment is required'),
        early_bird_investor: Yup.string().required('Select wether investor is Early bird or not'),
      });


  }
  else{
    CreateCashTransactionSchema = Yup.object().shape({
        source_bank_account: Yup.string().trim().required('Source bank account is required'),
        destination_bank_account: Yup.string().trim().required('Destination bank account is required'),
        transaction_ref_number:Yup.string().trim().required('Transaction Reference number is required'),
        token_quantity:Yup.number().required('Token quantity is required'),
        token_nav:Yup.number().required('Token NAV is required'),
        trade_consideration: Yup.string().required('Trade consideration'),
        fees: Yup.string().required('Fees is required'),
        total_payment: Yup.string().required('Total payment is required'),
      });
  }

  const formik = useFormik({
    initialValues,

    validate: (values) => {
      const errors = {};

      return errors;
    },

    validationSchema: CreateCashTransactionSchema,
    onSubmit: async (data, { resetForm }) => {
        setSubmitting(true);
        if (type === "SUBSCRIPTION"){

            data.token_subscription = recordId;
            data.token_transaction_type = "BUY";
        }
        else{
            data.token_redemption = recordId;
            data.token_transaction_type = "SELL";

        }
        data.type = type;
        const res = await createOfflineCashTransaction(data);
        if(res.status === "success"){
            enqueueSnackbar('Bank Account Transaction Data entered in the system successfully. Token transfer would happen shortly.', {
                variant: 'success'
              });
        }
        else{
            enqueueSnackbar('Data insertion failed.', {
                variant: 'error'
              });
        }
        formik.resetForm();
        setSubmitting(false);
        handleClose();
    }
  });
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;



  const handleClose = () => {
    setModalOpen(false);
    setSubmitting(false);
    formik.resetForm();
  };

  const handleOpen = async () => {
    let details;
    if (type === "SUBSCRIPTION"){
        details = await getSubscriptionDetailsById(recordId);
    }
    if (type === "REDEMPTION"){
        details = await getRedemptionDetailsById(recordId);
    }
    if(details.status === 'success'){
        setSubscriptionDetails(details);
        formik.setFieldValue('token_quantity',details.data.tokenQuantity);
        formik.setFieldValue('token_nav',details.data.tokenNav);
        formik.setFieldValue('trade_consideration',details.data.tokenQuantity*details.data.tokenNav);
        formik.setFieldValue('fees',(
            details.data.tokenNav *
            details.data.tokenQuantity *
            details.data.fees / 100
          ).toFixed(2), true);

        if (type === "SUBSCRIPTION"){
            formik.setFieldValue('total_payment',(
                details.data.tokenNav *
                details.data.tokenQuantity *
                (1 + details.data.fees / 100)
              ).toFixed(2), true);
        }
        else{
            formik.setFieldValue('total_payment',(
                details.data.tokenNav *
                details.data.tokenQuantity *
                (1 - details.data.fees / 100)
              ).toFixed(2), true);
        }
        if (details.data.entry_created === true){
          setViewOnly(true);
          formik.setFieldValue('source_bank_account',details.data.source_bank_account);
          formik.setFieldValue('destination_bank_account',details.data.destination_bank_account);
          formik.setFieldValue('transaction_ref_number',details.data.transaction_ref_number);
        }
        
        setModalOpen(true);
    }
    else{
        // show error message in snackbar
    }
  };

  const handleEarlyBirdChange = (value) =>{
    console.log('value - - - -', value);
    const { tokenQuantity, tokenFaceValue, tokenNav, fees } = subscriptonDetails.data;

    if (value === "YES"){
        formik.setFieldValue('token_quantity',tokenQuantity);
        formik.setFieldValue('token_nav',tokenFaceValue, true);
        formik.setFieldValue('trade_consideration',tokenQuantity*tokenFaceValue, true);
        formik.setFieldValue('fees',(
            tokenFaceValue *
            tokenQuantity *
            fees / 100
          ).toFixed(2), true);
        if (type === "SUBSCRIPTION"){
            formik.setFieldValue('total_payment',(
                tokenFaceValue *
                tokenQuantity *
                (1 + fees / 100)
              ).toFixed(2), true);
        }
        else{
            formik.setFieldValue('total_payment',(
                tokenFaceValue *
                tokenQuantity *
                (1 - fees / 100)
              ).toFixed(2), true);
        }
        formik.setFieldValue('early_bird_investor', value);
        console.log('formik.values - - -', formik.values)
    }
    if (value === "NO"){
        formik.setFieldValue('token_quantity',tokenQuantity);
        formik.setFieldValue('token_nav',tokenNav, true);
        formik.setFieldValue('trade_consideration',tokenQuantity*tokenNav, true);
        formik.setFieldValue('fees',(
            tokenNav *
            tokenQuantity *
            fees / 100
          ).toFixed(2), true);
        if (type === "SUBSCRIPTION"){
            formik.setFieldValue('total_payment',(
                tokenNav *
                tokenQuantity *
                (1 + fees / 100)
              ).toFixed(2), true);
        }
        else{
            formik.setFieldValue('total_payment',(
                tokenNav *
                tokenQuantity *
                (1 - fees / 100)
              ).toFixed(2), true);
        }
        formik.setFieldValue('early_bird_investor', value);
        console.log('formik.values - - -', formik.values);
    }
  }

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: 4,
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',  // Set max height for the modal
    overflowY: 'auto',   // Enable vertical scrolling
  };

  return (
    <>
      <PaidIcon
        sx={{
          fontSize: '2rem',
          p: 1,
          m: 0,

          backgroundColor: '#F5F8FA',

          mt: '0.2rem',
          mx: 1,
          cursor: 'pointer'
        }}
        onClick={handleOpen}
      />

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle} mt={2} >
        <FormikProvider value={formik} validator={() => ({})}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Card
                  sx={{
                    boxShadow: 'none',
                    border: '1px solid #D2D2D2',
                    borderRadius: '6px',
                    px: 4,
                    py: 1
                  }}
                >
                  <Typography sx={{ mt: 2, fontSize: '1.25rem', fontWeight: 'bold' }}>
                    Offline cash transaction details
                  </Typography>
                  <Grid container spacing={3} sx={{ width: '100%', py: 2 }}>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Source Bank Account</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        type="text"
                        InputProps={{ readOnly: viewOnly }}
                        // placeholder="Enter Your Name"
                        {...getFieldProps('source_bank_account')}
                        error={Boolean(touched.source_bank_account && errors.source_bank_account)}
                        helperText={touched.source_bank_account && errors.source_bank_account}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Dest. Bank Account</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        type="text"
                        InputProps={{ readOnly: viewOnly }}
                        {...getFieldProps('destination_bank_account')}
                        error={Boolean(touched.destination_bank_account && errors.destination_bank_account)}
                        helperText={touched.destination_bank_account && errors.destination_bank_account}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Transaction Ref number</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        type="text"
                        InputProps={{ readOnly: viewOnly }}
                        {...getFieldProps('transaction_ref_number')}
                        error={Boolean(touched.transaction_ref_number && errors.transaction_ref_number)}
                        helperText={touched.transaction_ref_number && errors.transaction_ref_number}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Token Quantity</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        type="number"
                        InputProps={{ readOnly: true }}
                        {...getFieldProps('token_quantity')}
                        error={Boolean(touched.token_quantity && errors.token_quantity)}
                        helperText={touched.token_quantity && errors.token_quantity}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Token NAV</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        type="number"
                        InputProps={{ readOnly: true }}
                        {...getFieldProps('token_nav')}
                        error={Boolean(touched.token_nav && errors.token_nav)}
                        helperText={touched.token_nav && errors.token_nav}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Trade consideration</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        type="number"
                        InputProps={{ readOnly: true }}
                        {...getFieldProps('trade_consideration')}
                        error={Boolean(touched.trade_consideration && errors.trade_consideration)}
                        helperText={touched.trade_consideration && errors.trade_consideration}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Fees</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        type="number"
                        InputProps={{ readOnly: true }}
                        {...getFieldProps('fees')}
                        error={Boolean(touched.fees && errors.fees)}
                        helperText={touched.fees && errors.fees}
                      />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                      <FormLabel>Total Payment</FormLabel>
                      <TextField
                        sx={{ mt: 1.5 }}
                        fullWidth
                        size="small"
                        type="number"
                        InputProps={{ readOnly: true }}
                        {...getFieldProps('total_payment')}
                        error={Boolean(touched.total_payment && errors.total_payment)}
                        helperText={touched.total_payment && errors.total_payment}
                      />
                    </Grid>

                    { type === "SUBSCRIPTION" && <Grid item lg={6} md={6} xs={12} sm={12}>
                      <FormLabel>Early Bird Investor?</FormLabel>

                      <FormControl size="small" variant="outlined" fullWidth sx={{ mt: 1.5 }}>
                        <Select
                          {...getFieldProps('early_bird_investor')}
                          onChange={(e)=>handleEarlyBirdChange(e.target.value)}
                          error={Boolean(touched.early_bird_investor && errors.early_bird_investor)}
                          helperText={touched.early_bird_investor && errors.early_bird_investor}
                        >
                          <MenuItem key="YES" value="YES">
                            Yes
                          </MenuItem>
                          <MenuItem key="NO" value="NO">
                            No
                          </MenuItem>
                        </Select>
                        <FormHelperText sx={{ color: '#d32f2f' }}>
                          {touched.early_bird_investor && errors.early_bird_investor}
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    }
                  </Grid>
                </Card>

                <Grid
                  container
                  sx={{
                    width: '100%',
                    mt: 2,
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end'
                  }}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    sx={{ display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Button
                      sx={{
                        px: 3,
                        mr: 1,
                        width: '7.75rem',
                        height: '2.5rem'
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </Button>
                    <LoadingButton
                      sx={{
                        width: '7.5rem',
                        height: '2.5rem'
                      }}
                      variant="gradient"
                      type="submit"
                      loadingPosition="start"
                      loading={submitting}
                      disabled = {viewOnly}
                    >
                      Submit
                    </LoadingButton>
                  </Grid>
                </Grid>
                <FocusError />
              </Form>
            </FormikProvider>


        </Box>
      </Modal>
    </>
  );
};

export default OfflineCashTransactionModal;
