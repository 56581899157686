import {
  AppBar,
  Container,
  IconButton,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  Box,
  Divider,
  Popover,
  Badge
} from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import React from 'react';
import dashboard from '../assets/images/dashboard.svg';
import ownerDashboard from '../assets/images/ownerDashboard.svg';
import masterdata from '../assets/images/masterdata.svg';
import Logo from './Logo';
import AccountPopover from '../layouts/main/AccountPopover';
import { useNavigate } from 'react-router';
import Notification from './masterData/Notification';
import { useAppState, useAuthStateShared } from 'state';

import WalletNavbar from 'components/WalletNavbar';
import Overlay from 'components/Overlay';
import RequireNetwork from 'components/RequireNetwork';
import MasterDataButton from './MasterDataButton';
import { useIsPrimaryIssuer } from 'helpers/rbac';
import BankAccountSetup from './BankAccountSetup';

const IssuerNavbar = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { notifications, fetchUserNotifications } = useAppState();
  const { user } = useAuthStateShared();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { role } = useAuthStateShared();
  const isPrimaryIssuer = useIsPrimaryIssuer();

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <AppBar
      position="sticky"
      sx={{
        backgroundColor: '#fff',
        height: user?.is_password_changed ? '8.125rem' : '4.9rem'
      }}
    >
      <Toolbar>
        <Box
          sx={{
            m: 2
          }}
        >
          <Logo sx={{ height: '2.9rem' }} />
          {/* <Box component="img" src="https://www.tradeteq.com/assets/images/tt-horizontal.svg" /> */}
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        <WalletNavbar />
        <Box sx={{ flexGrow: 1 }} />
        {user?.is_password_changed && (
          <IconButton sx={{ p: 2, mx: 1 }} onClick={handleClick}>
            <Badge badgeContent={notifications.length} color="primary">
              <NotificationsActiveIcon />
            </Badge>
          </IconButton>
        )}

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Notification />
        </Popover>
        <AccountPopover />
      </Toolbar>
      <Divider />
      {user?.is_password_changed && (
        <Box sx={{ position: 'relative', flex: '1' }}>
          <RequireNetwork not>
            <Overlay show />
          </RequireNetwork>
          <Container sx={{ p: '0 !important' }}>
            <Toolbar>
              <Button
                onClick={() => navigate('/home')}
                sx={{
                  color: '#161c2d'
                }}
              >
                <Box sx={{ mr: 2 }} component="img" src={dashboard} alt="dashboard  icon" />
                Dashboard
              </Button>
              <Box sx={{ flexGrow: 1 }} />

              {/* <BankAccountSetup /> */}
              <MasterDataButton />
            </Toolbar>
          </Container>
        </Box>
      )}
    </AppBar>
  );
};

export default IssuerNavbar;
