import {
  Chip,
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Alert,
  AlertTitle
} from '@mui/material';
import { getChipColor } from 'helpers/handler';
import React from 'react';
import { format, isBefore } from 'date-fns';
import NAIcon from 'components/NAIcon';

const AstraCashTransactionTable = ({ currToken: token }) => {
  console.log('🚀 ~ AstraCashTransactionTable ~ token:', token);
  const today = new Date();
  // get today as per issue date format
  const todayDate = format(today, 'yyyy-MM-dd');
  return (
    <>
      <Box>
        {/* Token Table */}
        <Card sx={{ mt: 2 }}>
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Token Name</TableCell>
                  <TableCell>Symbol</TableCell>
                  <TableCell>Issue Type</TableCell>
                  <TableCell>Currency</TableCell>
                  <TableCell>Issue Size</TableCell>
                  <TableCell>Issue Price</TableCell>
                  <TableCell>Total Supply</TableCell>
                  <TableCell>NAV</TableCell>
                  <TableCell>Allot. Qty</TableCell>
                  <TableCell>Pending TDA Fees</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{token?.token_name}</TableCell>
                  <TableCell>{token?.token_symbol}</TableCell>
                  <TableCell>{token?.issue_type_id?.issue_type_name}</TableCell>
                  <TableCell>{token?.token_currency?.currency_code}</TableCell>
                  <TableCell>{token?.max_issue_size}</TableCell>
                  <TableCell>{token?.token_value}</TableCell>
                  <TableCell>{token?.total_supply}</TableCell>
                  <TableCell>{token?.token_nav}</TableCell>
                  <TableCell>{token?.total_allotment}</TableCell>
                  <TableCell>{token?.pending_tda_fees}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
        {/* {token.token_status_id.token_status === 'BURNT' && (
            <Alert sx={{ mt: 2 }} severity="error">
              <AlertTitle>Alert</AlertTitle>
              Cannot perform any functions on — <strong>BURNT Token</strong>
              {`  ${token.token_name}`}
            </Alert>
          )} */}
        {/* {isBefore(new Date(token.final_maturity_date), new Date(todayDate)) && (
            <Alert sx={{ mt: 2 }} severity="error">
              <AlertTitle>Alert</AlertTitle>
              {`${token.token_name}`}, cannot be Allotted after the Maturity Date is closed
            </Alert>
          )} */}
      </Box>
    </>
  );
};

export default AstraCashTransactionTable;
