import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
  Tooltip
} from '@mui/material';
import MenuPopover from '../../components/MenuPopover';
import { useAuthState, useAuthStateShared, useWeb3, disconnectWallet } from 'state';

import { titleCase } from '../../helpers/text';
import { useIsPrimaryIssuer } from 'helpers/rbac';
import AddressFieldTools from 'components/AddressFieldTools';

const account = {
  displayName: 'Jaydon Frankie',
  email: 'demo@minimals.cc'
  // photoURL: '/static/mock-images/avatars/avatar_default.jpg'
};

const AccountPopover = () => {
  const isPrimaryIssuer = useIsPrimaryIssuer();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { logout, user, isInvestor } = useAuthState();
  const { role } = useAuthStateShared();
  const { disconnectMagicWallet, disconnectWallet, account: xinfinAddress } = useWeb3();
  const { name: userName, email: userEmail } = user ?? {};
  const location = useLocation();

  const MENU_OPTIONS = [
    {
      label: 'Home',
      icon: homeFill,
      linkTo: role === 'INVESTOR' ? '/subscription' : '/home',
      show: user?.is_password_changed
    },
    // {
    //   label: 'Profile',
    //   icon: personFill,
    //   linkTo: '#'
    // },
    {
      label: 'Manage Creditors',
      icon: personFill,
      linkTo: '/home/issuer-insurer-map',
      // show: isPrimaryIssuer,
      show: false
    },
    {
      label: 'Change Password',
      icon: settings2Fill,
      linkTo: role === 'INVESTOR' ? '/subscription/change-password' : '/home/change-password',
      show: location.pathname !== '/home/change-password'
    }
  ];
  return (
    <>
      <Tooltip title={userName} placement="bottom-start" arrow>
        <IconButton
          ref={anchorRef}
          onClick={handleOpen}
          sx={{
            padding: 0,
            width: 44,
            height: 44,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
              }
            })
          }}
        >
          {/*  if profile photo is uploaded */}
          {account.photoURL ? <Avatar src={account.photoURL} alt="photoURL" /> : <Avatar />}
        </IconButton>
      </Tooltip>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {userName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {userEmail}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {titleCase(role)}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            <AddressFieldTools
              address={xinfinAddress}
              decimals={0}
              showCopyButton
              showInBlockExplorer
            />
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {MENU_OPTIONS.map(
          (option) =>
            option.show && (
              <MenuItem
                key={option.label}
                to={option.linkTo}
                component={RouterLink}
                onClick={handleClose}
                sx={{ typography: 'body2', py: 1, px: 2.5 }}
              >
                <Box
                  component={Icon}
                  icon={option.icon}
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24
                  }}
                />

                {option.label}
              </MenuItem>
            )
        )}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => {
              try {
                if (isInvestor) {
                  disconnectMagicWallet();
                } else {
                  disconnectWallet();
                }
                logout();
              } catch (e) {
                console.log(e);
              }
            }}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
};

export default AccountPopover;
