import React, { useEffect, useMemo, useState, useRef } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  Divider,
  TablePagination,
  Card,
  Button,
  Checkbox,
  Typography
} from '@mui/material';
import TablePaginationActions from './TablePaginationActions';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import PropTypes from 'prop-types';
import TableToolbar from './TableToolbar';
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFlexLayout,
  useResizeColumns
} from 'react-table';

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;
  const { disabled } = rest;
  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <Checkbox disabled={disabled} sx={{ m: 0, p: 0 }} size="small" ref={resolvedRef} {...rest} />
    </>
  );
});

const CheckboxTokenTable = ({
  columns,
  data,
  updateMyData,
  skipPageReset,
  tableTitle,
  refreshFunction,
  setSelectedRowsToState,
  fieldName,
  disableRowCondition
}) => {
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    setPageSize,
    preGlobalFilteredRows,
    state,
    setGlobalFilter,
    toggleRowSelected,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter }
  } = useTable(
    {
      columns,
      data,
      autoResetPage: !skipPageReset,
      updateMyData,
      autoResetSelectedRows: false
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useResizeColumns,
    useRowSelect,
    useFlexLayout,
    (hooks) => {
      hooks.allColumns.push((columns) => [
        {
          id: 'selection',
          minWidth: 100,
          width: '100px',
          maxWidth: 100,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <IndeterminateCheckbox
              {...getToggleAllRowsSelectedProps()}
              onChange={() => toggleAllEnabledRows()}
            />
          ),
          Cell: ({ row }) => (
            <IndeterminateCheckbox
              {...row.getToggleRowSelectedProps()}
              disabled={disableRowCondition ? disableRowCondition(row.original) : false}
            />
          )
        },
        ...columns
      ]);
    }
  );

  // Function to toggle only enabled rows
  const toggleAllEnabledRows = () => {
    page.forEach((row) => {
      const isDisabled = disableRowCondition ? disableRowCondition(row.original) : false;
      toggleRowSelected(row.id, !isDisabled); // Toggle only if row is enabled
    });
  };

  const prevSelectedRowIds = useRef(selectedRowIds);

  const getAllSelectedRows = (fieldName = null) => {
    return Object.keys(selectedRowIds).reduce((acc, key) => {
      const row = data[key];
      if (fieldName) {
        acc.push(row[fieldName]);
      } else {
        acc.push(row);
      }
      return acc;
    }, []);
  };

  useEffect(() => {
    if (prevSelectedRowIds.current !== selectedRowIds) {
      const res = getAllSelectedRows(fieldName); // Pass the fieldName here
      setSelectedRowsToState(res);
      prevSelectedRowIds.current = selectedRowIds;
    }
  }, [selectedRowIds, data, setSelectedRowsToState, fieldName]);

  const handleChangePage = (event, newPage) => {
    gotoPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <Box>
      <TableToolbar
        refreshFunction={refreshFunction}
        tableTitle={tableTitle}
        numSelected={Object.keys(selectedRowIds).length}
        preGlobalFilteredRows={preGlobalFilteredRows}
        setGlobalFilter={setGlobalFilter}
        globalFilter={globalFilter}
      />
      <TableContainer>
        <Table size="small" className="table" {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, index) => (
              <TableRow sx={{ m: 0, p: 0 }} key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    sx={{ color: '#8e8ea7' }}
                    padding="normal"
                    key={column.id}
                    align={column.align}
                    {...column.getHeaderProps()}
                  >
                    <Box
                      {...column.getSortByToggleProps()}
                      sx={{ display: 'flex', flexDirection: 'row', mr: 1 }}
                    >
                      {column.render('Header')}
                      {column.id !== 'selection' ? (
                        <TableSortLabel
                          active={column.isSorted}
                          direction={column.isSortedDesc ? 'desc' : 'asc'}
                        />
                      ) : null}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow key={i} {...row.getRowProps()}>
                  {row.cells.map((cell, key) => (
                    <TableCell
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        my: -0.3,
                        mb: 0
                      }}
                      padding="normal"
                      size="small"
                      key={key}
                      {...cell.getCellProps()}
                    >
                      <Box
                        sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
                      >
                        {cell.render('Cell')}
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: data.length }]}
                count={data.length}
                rowsPerPage={Number(pageSize)}
                page={pageIndex}
                SelectProps={{ inputProps: { 'aria-label': 'rows per page' } }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Box>
  );
};

CheckboxTokenTable.propTypes = {
  tableTitle: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  updateMyData: PropTypes.func,
  skipPageReset: PropTypes.bool,
  refreshFunction: PropTypes.func.isRequired,
  setSelectedRowsToState: PropTypes.func.isRequired,
  fieldName: PropTypes.string, // New optional prop for fieldName
  disableRowCondition: PropTypes.func
};

export default CheckboxTokenTable;
