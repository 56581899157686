import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector, NoEthereumProviderError } from '@yodaplus/injected-connector';
import constate from 'constate';
import Onboard from 'bnc-onboard';
import { SafeAppConnector } from '@gnosis.pm/safe-apps-web3-react';

import WALLETS from 'config/wallets';
import {
  sendTransaction,
  sendTransactionHashOnly,
  WEB3_STATUS,
  currentNetwork,
  transformProviderFromXinfin,
  readOnlyWeb3,
  sendNativeTransaction,
  getTransactionHash
} from 'helpers/web3';
import { selectedWalletPersistence, userTokenPersistence } from 'persistence';
import { useAuthStateShared } from 'state/useAuthStateShared';
import tokenContractJson from '@yodaplus/dapps-lib/contracts/Token.json';
import { useAppState } from './useAppState';
import { useIsInvestor } from 'helpers/rbac';
import { useSnackbar } from 'notistack';
// import { NETWORK_ID, NETWORKS, API_URL } from '../config';

// magic link
import { OAuthExtension } from '@magic-ext/oauth';
import { AuthExtension } from '@magic-ext/auth';
import { Magic as MagicBase } from 'magic-sdk';
import { MAGIC_LINK_KEY } from '../config';
import { ethers } from 'ethers';
import { useMagicState } from './useMagicState';
import Web3 from 'web3';
import { ContactPageSharp } from '@mui/icons-material';
import { useAuthState } from './useAuthState';

const safeAppConnector = new SafeAppConnector();

const getOnboard = ({ onProvider }) => {
  const onboard = Onboard({
    networkId: currentNetwork.id,
    networkName: currentNetwork.name,
    subscriptions: {
      wallet: (wallet) => {
        if (wallet.provider && 'qrcodeModalOptions' in wallet.provider) {
          wallet.provider.connector._qrcodeModalOptions = {
            desktopLinks: []
          };
        }

        if (wallet.provider) {
          onProvider(transformProviderFromXinfin(wallet.provider));
        }
      }
    },
    walletSelect: {
      description: 'Please select a wallet to connect to Yodaplus Tokenization Platform',
      explanation:
        'Wallets are used to send, receive, and store digital assets. Wallets come in many forms. They are either built into your browser, an extension added to your browser, a piece of hardware plugged into your computer or even an app on your phone.',
      wallets: WALLETS
    },
    walletCheck: [
      { checkName: 'derivationPath' },
      { checkName: 'connect' },
      { checkName: 'accounts' }
    ]
  });

  return onboard;
};

const useMultisigStatus = () => {
  const [isMultisig, setIsMultisig] = useState(null);

  useEffect(() => {
    safeAppConnector.isSafeApp().then(setIsMultisig);
  }, []);

  return { isMultisig };
};

const useWeb3State_ = () => {
  const {
    activate,
    deactivate,
    active: _active,
    account: _account,
    library: _walletWeb3,
    chainId
  } = useWeb3React();
  const { isInvestor } = useAuthState();
  // const [chainId, setChainId] = useState(_chainId?? ChainId.XDCApothem)
  const [account, setAccount] = useState(_account);
  const [walletWeb3, setwalletWeb3] = useState(null);
  const [active, setActive] = useState(_active);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuthStateShared();

  const [web3Connecting, setWeb3Connecting] = useState(false);
  const { isAuthorized } = useAuthStateShared();

  const [status, setStatus] = useState(WEB3_STATUS.UNKNOWN);
  const { isMultisig } = useMultisigStatus();

  const { throwErrorMessage } = useAppState();

  const [otpVerificationOngoing, setOtpVerificationOngoing] = useState(false);
  const [otpVerificationComplete, setOtpVerificationComplete] = useState(false);
  const [connectedAccount, setConnectedAccount] = useState(_account);

  // magic link
  const [emailOtpHandler, setEmailOtpHandler] = useState(null);
  const [balance, setBalance] = useState('0');

  const [ethersProvider, setEthersProvider] = useState(null);
  const {
    magic,
    setMagic,
    initializeMagicSDK,
    disconnectMagic,
    submitLoginForm,
    setSubmitLoginForm
  } = useMagicState();
  const [userDetails, setUserDetails] = useState(null);

  const web3 = walletWeb3 ?? readOnlyWeb3;

  if (!web3) {
    throw new Error('web3 must be available at this point');
  }

  const tokenFactory = useMemo(() => {
    return new web3.eth.Contract(
      currentNetwork.tokenFactoryAbi,
      currentNetwork.tokenFactoryAddress,
      {
        from: account
      }
    );
  }, [web3, account]);

  const tokenContract = useMemo(() => {
    return new web3.eth.Contract(tokenContractJson.abi, {
      from: account
    });
  }, [web3, account]);

  if (!tokenContract || !tokenFactory) {
    throw new Error('contracts must be available at this point');
  }

  useEffect(() => {
    if (_active && _account) {
      console.log('🚀 ~ useEffect ~ _account:', _account);
      setActive(true);
      setAccount(_account);
      setwalletWeb3(_walletWeb3);
    }
  }, [_active, _account]);

  const wrapContractCall = useCallback(
    (func) => {
      return (...args) => {
        if (!tokenFactory) {
          throw new Error('Smart contract is not available');
        }

        return func(...args);
      };
    },
    [tokenFactory]
  );

  const onProvider = useCallback(
    async (provider) => {
      setStatus(WEB3_STATUS.UNKNOWN);

      const connector =
        typeof provider.safe !== 'undefined'
          ? safeAppConnector
          : new InjectedConnector({ provider });

      try {
        await activate(connector, undefined, true);
        setStatus(WEB3_STATUS.READY);
      } catch (e) {
        if (e instanceof NoEthereumProviderError) {
          setStatus(WEB3_STATUS.UNAVAILABLE);
        }
      }
    },
    [activate]
  );

  const onboard = useMemo(() => getOnboard({ onProvider }), [onProvider]);

  const connectWallet = useCallback(
    async (wallet) => {
      // console.log("WAllet",wallet)
      const selected = await onboard.walletSelect(wallet);
      if (!selected) {
        return false;
      }

      const ready = await onboard.walletCheck();
      if (!ready) {
        return false;
      }

      const {
        wallet: { name }
      } = onboard.getState();

      selectedWalletPersistence.set(name);

      return true;
    },
    [onboard]
  );

  const magicSDKSetup = () => {
    localStorage.setItem('currentNetwork', currentNetwork);
    const magicSDK = initializeMagicSDK();
    setMagic(magicSDK);
    const networkRPC = currentNetwork.rpcUrl;
    const _web3 = new Web3(transformProviderFromXinfin(magicSDK.rpcProvider));
    setwalletWeb3(_web3);
    return magicSDK;
  };

  useEffect(() => {
    if (!magic && isInvestor) {
      magicSDKSetup();
    }
  }, [isInvestor, currentNetwork, account, magic]);

  const connectMagicWallet = async (email, ref, inputRefs) => {
    // Check if magic is defined
    let _magic = magic;
    if (!_magic) {
      _magic = magicSDKSetup();
    }
    try {
      // Check if the user is already authenticated

      const isLoggedInResult = await _magic.user.isLoggedIn();

      if (typeof isLoggedInResult === 'boolean') {
        // isLoggedInResult is a boolean value, indicating the authentication status
        if (isLoggedInResult) {
          // If the user is already logged in, logout the user
          await _magic.user.logout();
          console.log('LOGOUT', await _magic.user.logout());
        }
        initiateMagicLogin(email, ref, inputRefs, _magic);
        setStatus(WEB3_STATUS.READY);
        // After logout or if the user was not logged in, initiate the login process using magic link
      } else {
        // Handle the case where isLoggedInResult is not a boolean (e.g., it might be an object)
        console.error('Unexpected result received from magic.user.isLoggedIn():', isLoggedInResult);
        setSubmitLoginForm(false);
      }

      return true;
    } catch (error) {
      console.error('Error checking authentication status or logging out:', error);
      setSubmitLoginForm(false);
    }
  };

  const initiateMagicLogin = (email, ref, inputRefs, magic) => {
    const _emailOtpHandler = magic?.auth.loginWithEmailOTP({
      email,
      showUI: false
    });
    _emailOtpHandler
      ?.on('email-otp-sent', () => {
        console.log('email-otp-sent');

        ref.current.click();
        console.log('inputRefs', inputRefs);
        setTimeout(() => {
          if (inputRefs[0].current) {
            console.log('inputRefs[0].current', inputRefs[0].current);
            inputRefs[0].current?.focus();
          }
        }, 1000);
        // Send the OTP for verification
        // _emailOtpHandler.emit('verify-email-otp', otp);
      })
      .on('error', (reason) => {
        // is called if the Promise rejects
        console.error('REASEDDDON', reason);
        setOtpVerificationOngoing(false);
      });
    setEmailOtpHandler(_emailOtpHandler);
    return _emailOtpHandler;
  };

  const verifyOtp = useCallback(
    async (otp, ref, count) => {
      try {
        emailOtpHandler?.emit('verify-email-otp', otp);
        emailOtpHandler?.on('invalid-email-otp', () => {
          enqueueSnackbar('Invalid OTP', { variant: 'error', preventDuplicate: true });
          setOtpVerificationOngoing(false);
          if (count === 2) {
            emailOtpHandler?.emit('cancel');
            ref?.current?.click();
            enqueueSnackbar('You have exceeded the maximum number of tries', {
              variant: 'error'
            });
          }
        });
        emailOtpHandler?.on('done', async () => {
          setOtpVerificationComplete(false);
          const userDetails = await magic?.user.getInfo();
          console.log('🚀 ~ emailOtpHandler?.on ~ userDetails:', userDetails);
          const balance = await ethersProvider?.getBalance(userDetails?.publicAddress ?? '');
          setBalance(balance ? ethers.utils.formatEther(balance) : '');
          localStorage.setItem('token', JSON.stringify(userDetails?.publicAddress));
          setUserDetails({ email: userDetails?.email ?? '' });
          setActive(true);
          setConnectedAccount(userDetails?.publicAddress ?? '');
          setWeb3Connecting(false);
          setAccount(userDetails?.publicAddress ?? '');
          ref?.current?.click();

          setOtpVerificationOngoing(false);
          setOtpVerificationComplete(true);
          setStatus(WEB3_STATUS.READY);
          // setIsReady(true)
        });
        emailOtpHandler?.on('error', (reason) => {
          // is called if the Promise rejects
          console.error('REASEDDDON', reason);
          setOtpVerificationOngoing(false);
        });
        return true;
      } catch (error) {
        console.log('error', error);
        throwErrorMessage(error);
        setOtpVerificationOngoing(false);
      }
    },
    [emailOtpHandler, magic]
  );

  useEffect(() => {
    const token = userTokenPersistence.get();

    try {
      const fetchdata = async () => {
        if (magic && token && isInvestor) {
          const isLoggedIn = await magic?.user.isLoggedIn();
          // console.log('isLoggedIn', isLoggedIn);
          if (isLoggedIn) {
            const userDetails = await magic?.user.getInfo();
            localStorage.setItem('token', JSON.stringify(userDetails?.publicAddress));
            setUserDetails({ email: userDetails?.email ?? '' });
            setActive(true);
            setAccount(userDetails?.publicAddress ?? '');
            setConnectedAccount(userDetails?.publicAddress ?? '');
            setWeb3Connecting(false);
          }
        }
      };
      fetchdata();
    } catch (error) {
      console.error('Error fetching data, might Logged OUT:', error);
    }
  }, [magic, isInvestor]);

  const disconnectMagicWallet = async () => {
    try {
      disconnectMagic();
      setAccount('');
      setActive(false);
      setwalletWeb3(readOnlyWeb3);
    } catch (e) {
      console.log(e);
    }
  };

  const disconnectWallet = async () => {
    onboard.walletReset();
    deactivate();
    selectedWalletPersistence.clear();
    setAccount('');
    setActive(false);
    setwalletWeb3(readOnlyWeb3);
  };

  // We disable the following eslint rule,
  // because we know what dependencies wrapContractCall(...) has
  /* eslint-disable react-hooks/exhaustive-deps */

  const tokenOwner = useCallback(
    wrapContractCall((address) => {
      tokenContract.options.address = address;
      return tokenContract.methods.owner().call();
    }),
    [wrapContractCall, tokenContract]
  );

  const pause = useCallback(
    wrapContractCall((tokenAddress) => {
      tokenContract.options.address = tokenAddress;
      return sendTransactionHashOnly(web3, tokenContract.methods.pause());
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const unpause = useCallback(
    wrapContractCall((tokenAddress) => {
      tokenContract.options.address = tokenAddress;
      return sendTransactionHashOnly(web3, tokenContract.methods.unpause());
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const publishToken = useCallback(
    wrapContractCall((tokenName, tokenSymbol, tokenTotalSupply, tokenNav) => {
      console.log('PUBLISH TOKEN', tokenName, tokenSymbol, tokenTotalSupply, tokenNav);
      return sendTransactionHashOnly(
        web3,
        tokenFactory.methods.publishToken(tokenName, tokenSymbol, tokenTotalSupply, tokenNav)
      );
    }),
    [wrapContractCall, web3, tokenFactory]
  );

  const giveRedeemAllowance = useCallback(
    wrapContractCall((deploymentAddress, issuerAddress, tokenQuantity) => {
      console.log('give redeem allowance', deploymentAddress, issuerAddress, tokenQuantity);
      tokenContract.options.address = deploymentAddress;
      return sendTransaction(web3, tokenContract.methods.approve(issuerAddress, tokenQuantity));
    }),
    [wrapContractCall, web3, tokenContract]
  );

  // ERC20 Contract Functions
  const getERC20Name = useCallback(
    wrapContractCall((tokenAddress) => {
      tokenContract.options.address = tokenAddress;
      return tokenContract.methods.name().call();
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const getERC20Symbol = useCallback(
    wrapContractCall((tokenAddress) => {
      tokenContract.options.address = tokenAddress;
      return tokenContract.methods.symbol().call();
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const getERC20Decimals = useCallback(
    wrapContractCall((tokenAddress) => {
      tokenContract.options.address = tokenAddress;
      return tokenContract.methods.decimals().call();
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const getBalanceOf = useCallback(
    wrapContractCall((tokenAddress, walletAddress = account) => {
      tokenContract.options.address = tokenAddress;
      return tokenContract.methods.balanceOf(walletAddress).call();
    }),
    [wrapContractCall, web3, tokenContract]
  );
  const getTotalSupply = useCallback(
    wrapContractCall((tokenAddress) => {
      tokenContract.options.address = tokenAddress;
      return tokenContract.methods.totalSupply().call();
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const tokenGiveAllowance = useCallback(
    wrapContractCall((tokenAddress, value) => {
      tokenContract.options.address = tokenAddress;
      return sendTransactionHashOnly(
        web3,
        tokenContract.methods.approve(currentNetwork.escrowManagerAddress, value)
      );
    })
  );

  const setMaxSupply = useCallback(
    wrapContractCall((tokenAddress, value) => {
      tokenContract.options.address = tokenAddress;
      return sendTransactionHashOnly(web3, tokenContract.methods.setMaxSupply(value));
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const setNav = useCallback(
    wrapContractCall((tokenAddress, value) => {
      tokenContract.options.address = tokenAddress;
      return sendTransactionHashOnly(web3, tokenContract.methods.setNAV(value));
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const mint = useCallback(
    wrapContractCall((tokenAddress, value) => {
      tokenContract.options.address = tokenAddress;
      return sendTransactionHashOnly(web3, tokenContract.methods.mint(value));
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const burn = useCallback(
    wrapContractCall((tokenAddress, value) => {
      tokenContract.options.address = tokenAddress;
      return sendTransactionHashOnly(web3, tokenContract.methods.burn(value));
    }),
    [wrapContractCall, web3, tokenContract]
  );

  const transfer = useCallback(
    wrapContractCall((tokenAddress, to, value) => {
      tokenContract.options.address = tokenAddress;
      console.log(to, value, account);
      return sendTransactionHashOnly(web3, tokenContract.methods.transfer(to, value));
    }),
    [wrapContractCall, web3, tokenContract]
  );
  useEffect(() => {
    if (isMultisig === null || isMultisig === true) {
      return;
    }

    (async () => {
      const selectedWallet = selectedWalletPersistence.get();

      if (!selectedWallet) {
        return;
      }

      const connected = await connectWallet(selectedWallet);

      if (!connected) {
        selectedWalletPersistence.clear();
      }
    })();
  }, [isMultisig, isAuthorized]);

  /* eslint-enable react-hooks/exhaustive-deps */

  return {
    isMultisig,
    status,
    active,
    account,
    setAccount,
    chainId,
    web3,
    connectWallet,
    disconnectWallet,
    publishToken,
    tokenOwner,
    pause,
    unpause,
    getERC20Name,
    getERC20Symbol,
    getERC20Decimals,
    tokenGiveAllowance,
    setMaxSupply,
    getBalanceOf,
    getTotalSupply,
    verifyOtp,
    connectMagicWallet,
    otpVerificationOngoing,
    setOtpVerificationOngoing,
    connectedAccount,
    otpVerificationComplete,
    setOtpVerificationComplete,
    disconnectMagicWallet,
    mint,
    setNav,
    giveRedeemAllowance,
    burn,
    transfer
  };
};

export const [Web3StateProvider, useWeb3State] = constate(useWeb3State_);
